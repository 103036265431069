import React, { useEffect, useState } from "react";
import { listElements } from "src/pages/admin/settingV3/library";
import { useNavigate } from "react-router-dom";
import localforage from "localforage";
import { loadImage } from "src/functions/utils";
import { useTranslation } from "react-i18next";

const UserElement = ({ data }) => {
  const [bio, setBio] = useState({});
  const navigate = useNavigate();
  const [src, setSrc] = useState("");
  const [poster, setPoster] = useState("");
  const { i18n } = useTranslation();

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(async () => {
    if (data?.variant === "element2") {
      // video
      const resNewGet = await loadImage(data.src);
      const resPoster = await loadImage(data.poster);
      console.log(data.src, resNewGet);
      setSrc(resNewGet);
      setPoster(resPoster);
    }
  }, []);

  const fetchData = async () => {
    const resBio = await localforage.getItem("bio");
    setBio(resBio);
  };

  const navFilter = (data) => {
    // data?.variant === "element10" || data?.variant === "element4"
    //   ? window.location.replace(data.url)
    //   : navigate(data.url);
    if (data.url && data.url.indexOf("user") !== -1) navigate(data.url);
    else {
      if (data.url) {
        const needHttp = data.url.indexOf("http") === -1 ? "https://" : "";
        window.location.replace(`${needHttp}${data.url}`);
      }
    }
    // if (url.indexOf(bio.domain) !== -1) {
    //   let link;
    //   if (bio.domain === "localhost") link = url.split(bio.domain + ":3000");
    //   else link = url.split(bio.domain);
    //   navigate(link[1]);
    // } else {
    //   window.location.replace(data.url);
    // }
  };
  return (
    <div
      style={{
        display: data.variant === "element0" && "block",
        width:
          data?.variant === "element1" ||
          data?.variant === "element2" ||
          data?.variant === "element3" ||
          data?.variant === "element11" ||
          data?.variant === "element12" ||
          data?.variant === "element21" ||
          data?.variant === "element22" ||
          data?.variant === "element31" ||
          data?.variant === "element41" ||
          data?.variant === "element51" ||
          data?.variant === "element61" ||
          data?.variant === "element90" ||
          data?.variant === "element91"
            ? "100%"
            : null,
      }}
      onClick={() => navFilter(data)}
    >
      {listElements(data, i18n.language, src, poster)}
    </div>
  );
};
export default UserElement;
