import React from "react";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import "src/App.css";

const RunningText = ({ data }) => {
  const { i18n } = useTranslation();

  return (
    <>
      <Typography
        className={data.elCn}
        variant={"body2"}
        component="p"
        sx={{
          fontWeight: "bold",
          color: "#ffff",
          p: 1,
          pl: "1rem",
          ...data.props,
        }}
      >
        {i18n.language === "en" ? data.textEn : data.text}
      </Typography>
      <Typography
        className={data.elCn}
        variant={"body2"}
        component="p"
        sx={{
          fontWeight: "bold",
          color: "#ffff",
          p: 1,
          pl: "1rem",
          ...data.props,
        }}
      >
        {i18n.language === "en" ? data.textEn : data.text}
      </Typography>
    </>
  );
};
export default RunningText;
