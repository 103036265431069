import React, { useState, useRef, useEffect } from "react";
import { useDrag } from "react-dnd";
import Column from "./Column";
import { Grid, Stack, useMediaQuery } from "@mui/material";
import DropZone from "../DropZone";
import { ROW } from "../library";
import { loadImage } from "src/functions/utils";
import "../../../../App.css";

const Row = ({
  data,
  rowObjHover,
  setRowObjHover,
  setStateDrawer,
  stateDrawer,
  rowObjEdit,
  setRowObjEdit,
  handleDrop,
  path,
  dropArea,
  setDropArea,
  setOpenConfirm,
}) => {
  const isScreen2000 = useMediaQuery("(min-width:2000px)");
  const isScreen1536 = useMediaQuery("(min-width:1536px)");
  const isScreen1440 = useMediaQuery("(min-width:1440px)");
  const isScreen1200 = useMediaQuery("(min-width:1200px)");
  const isScreen1700 = useMediaQuery("(min-width:1700px)");
  const isScreen900 = useMediaQuery("(min-width:900px)");
  const ref = useRef(null);
  const [src, setSrc] = useState("");
  const [bgImg, setBgImg] = useState("");
  const [poster, setPoster] = useState("");
  const [bg, setBg] = useState("");

  const [{ isDragging }, drag] = useDrag({
    item: {
      type: ROW,
      id: data.id,
      children: data.children,
      path,
    },
    collect: (monitor) => ({ isDragging: monitor.isDragging() }),
  });

  useEffect(async () => {
    const resNewGet = await loadImage(data.src, "video");
    const resBgImg = await loadImage(data.sectProps?.backgroundImage);
    const resPoster = await loadImage(data.poster);
    const resBg = await loadImage(data.props?.backgroundImage);
    setSrc(resNewGet);
    console.log(resNewGet, data.src)
    setBgImg(resBgImg);
    setPoster(resPoster);
    setBg(resBg);
  }, [data]);

  const renderColumn = (column, rowId, currentPath) => {
    return (
      <Column
        key={column.id}
        data={column}
        rowId={rowId}
        rowObjHover={rowObjHover}
        setRowObjHover={setRowObjHover}
        setStateDrawer={setStateDrawer}
        rowObjEdit={rowObjEdit}
        setRowObjEdit={setRowObjEdit}
        handleDrop={handleDrop}
        path={currentPath}
        dropArea={dropArea}
        setOpenConfirm={setOpenConfirm}
      />
    );
  };

  const maxWidthStyle = (maxWidth) => {
    if (stateDrawer.indexOf("fix") !== -1) return "87.5%";
    else if (isScreen2000) return "2000px";
    else if (isScreen900 && !isScreen2000 && maxWidth) return maxWidth;
    else if (isScreen1536) return "1440px";
    else if (isScreen1440) return "1200px";
    else if (isScreen1200) return "1024px";
    else if (!isScreen1200) return "900px";
  };
  // dibawah 900 buka modal ga tersedia

  return (
    <Stack
      component="section"
      ref={drag(ref)}
      className={data.sectCn && `sect ${data.sectCn}`}
      sx={{
        background: bgImg && `no-repeat center url(${bgImg})`,
        backgroundSize: bgImg && "cover",
        display:
          (data.sectProps?.alignItems || data.sectProps?.justifyContent) &&
          "flex",
        maxWidth: isScreen1700 ? "1700px" : null,
        m: "auto",
        opacity: isDragging ? 0 : 1,
        ...data.sectProps,
      }}
    >
      {data.src && (
        <video
          id="video-bg"
          style={{ aspectRatio: data.sectProps?.aspectRatio }}
          poster={poster}
          preload
          autoPlay
          autoBuffer="true"
          loop
          playsInline
          muted
        >
          <source src={src} type="video/mp4"></source>
          <p>Your browser doesn't support HTML video.</p>
        </video>
      )}
      <Grid
        container
        id={data.id}
        className={data.rowCn && `row ${data.rowCn}`}
        sx={{
          background: bg && `no-repeat center url(${bg})`,
          backgroundSize: bg && "cover",
          display:
            (data.props?.alignItems || data.props?.justifyContent) && "flex",
          position: "unset",
          ...data.props,

          maxWidth: maxWidthStyle(data.props.maxWidth),
          m: "auto",
        }}
      >
        {data?.children.map((column, index) => {
          const currentPath = `${path}-${index}`;

          return (
            <React.Fragment key={column.id}>
              <DropZone
                data={{
                  path: currentPath,
                  childrenCount: data.children.length,
                }}
                className="horizontal"
                setDropArea={setDropArea}
                dropArea={dropArea}
                onDrop={handleDrop}
              />
              {renderColumn(column, data.id, currentPath)}
            </React.Fragment>
          );
        })}
      </Grid>
    </Stack>
  );
};
export default Row;
