import {
  SET_NAV_PREVIEW,
  SET_LOGO_PREVIEW,
  SET_THEME_PREVIEW,
  SET_CONTENT_PREVIEW,
  SET_IS_PREVIEW,
  SET_CONTENT_HOVER_ID,
  SET_PAGE_PREVIEW,
  SET_ARTICLE_PREVIEW,
  SET_TESTI_PREVIEW,
  SET_GAL_PREVIEW,
  SET_BTN_PREVIEW,
  SET_IS_CHANGE,
  SET_HEIGHT_HEADER,
  SET_BACKDROP,
  SET_NAV_CHANGED,
} from "../events";
import { version } from "src/configs/globalVariable";

const initialState = {
  isPreview: false,
  navPreview: [],
  contentPreview: [],
  logoPreview: "",
  themePreview: {},
  articlePreview: [],
  testiPreview: [],
  galPreview: [],
  buttons: {},
  contentHoverId: "",
  isChange: {},
  // new
  heightHeader: "",
  pagePreview: `/${version}/user`,
  backdrop: false,
  navChanged: false,
};

const preview = (state = initialState, action) => {
  switch (action.type) {
    case SET_NAV_PREVIEW:
      return { ...state, navPreview: action.payload };
    case SET_LOGO_PREVIEW:
      return { ...state, logoPreview: action.payload };
    case SET_THEME_PREVIEW:
      return { ...state, themePreview: action.payload };
    case SET_CONTENT_PREVIEW:
      return { ...state, contentPreview: action.payload };
    case SET_IS_PREVIEW:
      return { ...state, isPreview: action.payload };
    case SET_CONTENT_HOVER_ID:
      return { ...state, contentHoverId: action.payload };
    case SET_PAGE_PREVIEW:
      return { ...state, pagePreview: action.payload };
    case SET_ARTICLE_PREVIEW:
      return { ...state, articlePreview: action.payload };
    case SET_TESTI_PREVIEW:
      return { ...state, testiPreview: action.payload };
    case SET_GAL_PREVIEW:
      return { ...state, galPreview: action.payload };
    case SET_IS_CHANGE:
      return { ...state, isChange: action.payload };
    case SET_BTN_PREVIEW:
      return { ...state, buttons: action.payload };
    case SET_HEIGHT_HEADER:
      return { ...state, heightHeader: action.payload };
    case SET_BACKDROP:
      return { ...state, backdrop: action.payload };
    case SET_NAV_CHANGED:
      return { ...state, navChanged: action.payload };
    default:
      return state;
  }
};

export default preview;
