import React from "react";
import { Grid, Stack, Typography } from "@mui/material";

const ContainerCardProduct = () => {
  return (
    // <Stack direction="row" sx={{ width: "100%" }}>
    //   <Grid container sx={{ width: "80%" }}></Grid>
    //   <Stack direction="column" component="aside" sx={{ width: "20%" }}>
    //     <Typography variant="body2">Front end Developer</Typography>
    //     <Typography variant="body2">React Developer</Typography>
    //   </Stack>
    // </Stack>
    <Grid item container spacing={2}>
      <Grid
        item
        container
        md={10}
        sx={{ display: "flex", justifyContent: "space-evenly" }}
      >
        <div
          style={{
            height: "10rem",
            width: "9rem",
            backgroundColor: "lightgrey",
          }}
        ></div>
        <div
          style={{
            height: "10rem",
            width: "9rem",
            backgroundColor: "lightgrey",
          }}
        ></div>
        <div
          style={{
            height: "10rem",
            width: "9rem",
            backgroundColor: "lightgrey",
          }}
        ></div>
        <div
          style={{
            height: "10rem",
            width: "9rem",
            backgroundColor: "lightgrey",
          }}
        ></div>
        <div
          style={{
            height: "10rem",
            width: "9rem",
            backgroundColor: "lightgrey",
          }}
        ></div>
        <div
          style={{
            height: "10rem",
            width: "9rem",
            backgroundColor: "lightgrey",
          }}
        ></div>
      </Grid>
      <Grid item component="aside" md={2} sx={{}}>
        <Typography sx={{ display: "block" }} variant="caption">
          Front-end Web Development
        </Typography>
        <Typography sx={{ display: "block" }} variant="caption">
          React Web Development
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ContainerCardProduct;
