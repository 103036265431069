import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  TextField,
  useTheme,
  InputAdornment,
  IconButton,
  styled,
  CircularProgress,
  Container,
  Paper,
  Grid,
  Stack,
  Tooltip,
} from "@mui/material";
import { Email, Home, HomeRounded, Lock } from "@mui/icons-material";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import {
  loginForm,
  loginGoogle,
  setAuthenticated,
  setOpenLogin,
  setSnackbarOpen,
  emailCheckRegister,
  sendForgotPassword,
  checkInvitedGenerator,
  setDeauthenticated,
  candidateCheckApply,
} from "src/redux/actions";
import { useNavigate } from "react-router";
import { FcGoogle } from "react-icons/fc";
import localforage from "localforage";
import { useParams } from "react-router";
import { version } from "src/configs/globalVariable";

const CustomCircularProgress = styled(CircularProgress)(
  ({ theme, loading }) => ({
    "&.MuiCircularProgress-root": {
      height: "20px !important",
      width: "20px !important",
      display: "flex",
      color: loading === "google" ? `${theme.palette.primary.main}` : "#ffff",
      marginRight: loading === "google" ? "0.5rem !important" : null,
    },
  })
);

const CustomTextField = styled(TextField)(({ theme }) => ({
  "&.Mui-focused": {
    border: `0.1px solid ${theme.palette.primary.main}`,
  },
  "&:hover fieldset": {
    border: `0.1px solid ${theme.palette.primary.main} !important`,
  },
}));

const Invited = () => {
  const params = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const [form, setForm] = useState({ email: "", password: "" });
  const [showPass, setShowPass] = useState(false);
  const [loading, setLoading] = useState(true);
  const [validate, setValidate] = useState(false);
  const [isRegist, setIsRegist] = useState(false);
  const [data, setData] = useState("");

  useEffect(() => {
    setValidate(false);
    // setLoading(false);
    // setIsRegist(false);
    fetchData();
  }, []);

  const fetchData = async () => {
    await localforage.removeItem("token");
    await localforage.removeItem("userRole");
    await localforage.removeItem("fullname");
    localStorage.removeItem("invited");
    await setDeauthenticated();
    const res = await checkInvitedGenerator({
      invited_generator_id: params.token,
    });
    if (!res.data?.invited_generator_id) {
      setData(res.data.message);
      setSnackbarOpen("error", res.data.message);
      setLoading(false);
      return;
    }
    setData(res.data);
    localStorage.setItem("invited", JSON.stringify(res.data));
    setLoading(false);
  };

  const handleChange = (e) => {
    const copyForm = { ...form };
    copyForm[e.target.name] = e.target.value;
    setForm(copyForm);
  };

  const login = async () => {
    if (!form.email || !form.password) {
      setValidate(true);
      return;
    }
    setLoading("login");
    const resLogin = await loginForm(form);

    if (resLogin?.code === 200) {
      setOpenLogin(false);
      setAuthenticated(resLogin.data.token);
      const resCheck = await candidateCheckApply({
        job_posting_id: data?.job_posting_id,
      });
      if (resCheck.code === 200) {
        navigate(`/${version}/user/profile`);
        setTimeout(() => {
          window.location.reload();
        }, "500");
      } else {
        localStorage.removeItem("invited");
        navigate(`/${version}/user/dashboard/my-career`);
        setSnackbarOpen("error", resCheck.data.message);
      }
    } else if (resLogin?.code === 400 || resLogin?.code === 403) {
      setSnackbarOpen("error", resLogin.message);
    }
    setLoading(false);
  };

  function isValidEmail(email) {
    // Ekspresi reguler untuk memeriksa validitas alamat email
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    return emailPattern.test(email);
  }

  const register = async () => {
    await localforage.removeItem("token");
    await localforage.removeItem("userRole");
    await localforage.removeItem("fullname");
    if (!isValidEmail(form.email)) {
      setSnackbarOpen("error", "Email tidak valid");
      return;
    }

    setLoading("login");
    const resLogin = await emailCheckRegister({ email: form.email });
    setLoading(false);

    if (resLogin.code === 409) {
      setSnackbarOpen("error", resLogin.message);
      return;
    }

    if (resLogin.code === 200) {
      await localforage.setItem("email", form.email);
      setOpenLogin(false);
      navigate(`/${version}/register`);
      return;
    }
  };

  const handleGoogle = async () => {
    setLoading("google");
    const resGoogle = await loginGoogle();

    if (resGoogle.code === 200) {
      setOpenLogin(false);
      setAuthenticated(resGoogle.data.token);
      navigate(`/${version}/user/profile`);
      setTimeout(() => {
        window.location.reload();
      }, "500");
    } else if (resGoogle.code === 400 || resGoogle.code === 403) {
      setSnackbarOpen("error", resGoogle.message);
    } else if (resGoogle.status === 403) {
      setOpenLogin(false);
      navigate(`/${version}/register`);
      return;
    }
    setLoading(false);
  };

  if (loading) return <CircularProgress />;

  return (
    <Grid container sx={{ width: "100dvw", height: "100dvh" }}>
      <Paper
        sx={{ maxWidth: "md", margin: "auto", p: "2rem", borderRadius: "8px" }}
      >
        {typeof data === "string" && (
          <Stack>
            <img
              src="https://colagio.shratech.com/assets/files/1.logo%20pama.png"
              alt="logo"
              style={{ width: "90px", margin: "auto" }}
            />
            <Typography sx={{ mt: "1rem", fontWeight: "bold" }}>
              {data}
            </Typography>
            <Tooltip title="Home">
              <IconButton
                sx={{ width: "fit-content", m: "auto" }}
                onClick={() => navigate("/")}
              >
                <HomeRounded />
              </IconButton>
            </Tooltip>
          </Stack>
        )}

        {typeof data !== "string" && (
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <img
                src="https://colagio.shratech.com/assets/files/1.logo%20pama.png"
                alt="logo"
                style={{ width: "90px" }}
              />
              <Typography variant="h5" sx={{ mb: 1 }}>
                Undangan Eksklusif: {data?.position_group_name}!
              </Typography>
              <Typography gutterBottom sx={{ fontSize: "14px" }}>
                Selamat datang! Anda diundang secara eksklusif untuk mendaftar
                pada lowongan pekerjaan {data?.position_group_name} melalui link
                ini.
              </Typography>
              <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
                Info Tambahan: Perlu diingat bahwa kuota pendaftaran terbatas,
                jadi segera lengkapi formulir pendaftaran
              </Typography>
              <Typography sx={{ mt: "1rem", fontSize: "14px" }}>
                Jika Anda sudah memiliki akun, silakan klik "Login". Jika belum,
                silakan klik "Daftar".
              </Typography>
            </Grid>
            <Grid item md={6} xs={12} sx={{ m: "auto" }}>
              <Typography variant="h4" sx={{ mb: 4 }}>
                {isRegist ? "Daftar" : "Login"}
              </Typography>
              <CustomTextField
                placeholder="Email"
                type="email"
                name="email"
                variant="outlined"
                color="primary"
                hiddenLabel
                fullWidth
                error={validate && !form.email}
                helperText={validate && !form.email && "* email harap diisi"}
                value={form.email}
                onChange={handleChange}
                size="small"
                InputProps={{
                  startAdornment: (
                    <Email
                      sx={{ color: `${theme.palette.primary.main}`, mr: 1 }}
                    />
                  ),
                }}
              />
              {!isRegist && (
                <>
                  <CustomTextField
                    placeholder="Kata sandi"
                    type={showPass ? "text" : "password"}
                    variant="outlined"
                    color="primary"
                    name="password"
                    hiddenLabel
                    fullWidth
                    error={validate && !form.password}
                    helperText={
                      validate && !form.password && "* Kata sandi harap diisi"
                    }
                    sx={{ mt: 2 }}
                    value={form.password}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <Lock
                          sx={{ color: `${theme.palette.primary.main}`, mr: 1 }}
                        />
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPass(!showPass)}
                            onMouseDown={(e) => e.preventDefault()}
                            edge="end"
                          >
                            {showPass ? (
                              <Visibility color="primary" />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    size="small"
                  />
                  <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    fullWidth
                    onClick={loading === "login" ? null : login}
                    sx={{
                      fontWeight: 700,
                      borderRadius: "5rem",
                      transition: "all linear .4s",
                      mt: 3,
                      "&:hover": {
                        transform: "scale(1.05)",
                      },
                    }}
                  >
                    {loading === "login" ? <CustomCircularProgress /> : "Login"}
                  </Button>
                  <Button
                    variant="outlined"
                    size="medium"
                    color="primary"
                    fullWidth
                    onClick={loading === "google" ? null : handleGoogle}
                    sx={{
                      mt: 2,
                      mb: 1,
                      display: "flex",
                      alignItems: "center",
                      borderRadius: "5rem",
                      transition: "all linear .4s",
                      fontWeight: 600,
                      "&:hover": {
                        transform: "scale(1.05)",
                      },
                    }}
                  >
                    {loading === "google" ? (
                      <CustomCircularProgress loading="google" />
                    ) : (
                      <FcGoogle
                        style={{
                          height: "40px !important",
                          marginRight: "0.5rem",
                        }}
                      />
                    )}

                    <Typography variant="span">Daftar / Masuk</Typography>
                  </Button>
                  <Typography
                    sx={{ fontSize: "14px", textAlign: "center", mb: "1rem" }}
                  >
                    Belum memiliki akun?{" "}
                    <em
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        color: "#3f51b5",
                      }}
                      onClick={() => setIsRegist(true)}
                    >
                      Daftar
                    </em>
                  </Typography>
                </>
              )}
              {isRegist && (
                <>
                  <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    fullWidth
                    onClick={loading === "login" ? null : register}
                    sx={{
                      fontWeight: 700,
                      borderRadius: "5rem",
                      transition: "all linear .4s",
                      mt: 3,
                      mb: 2,
                      "&:hover": {
                        transform: "scale(1.05)",
                      },
                    }}
                  >
                    {loading === "login" ? (
                      <CustomCircularProgress />
                    ) : (
                      "Daftar"
                    )}
                  </Button>
                  <Typography
                    sx={{ fontSize: "14px", textAlign: "center", mb: "1rem" }}
                  >
                    Sudah memiliki akun?{" "}
                    <em
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                        color: "#3f51b5",
                      }}
                      onClick={() => setIsRegist(false)}
                    >
                      Login
                    </em>
                  </Typography>
                </>
              )}
            </Grid>
          </Grid>
        )}
      </Paper>
    </Grid>
  );
};

export default Invited;
