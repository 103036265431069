import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Box,
  Tabs,
  Tab,
  CircularProgress,
  Stack,
  Divider,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
} from "@mui/material";
import PropTypes from "prop-types";
import { useNavigate } from "react-router";
import "src/App.css";
import CustomInput from "src/components/CustomInput";
import PersonalData from "./PersonalData";
import Family from "./Family";
import Education from "./Education";
import Organization from "./Organization";
import Experience from "./Experience";
import PersonalInfo from "./PersonalInfo";
import UploadDocument from "./UploadDocument";
import Illness from "./Illness";
import {
  checkAgreePrivacy,
  updateAgreePrivacy,
  checkOnRecruitment,
  downloadFLK,
} from "src/redux/actions";
import localforage from "localforage";
import LoadableImage from "src/components/LoadableImage";
import { version } from "src/configs/globalVariable";

const styleGrid = {
  p: 4,
  backgroundColor: "#ffff",
  m: "auto",
  mt: "-2rem",
  borderRadius: "0.75rem",
  boxShadow: "rgba(0, 0, 0, 0.09) 0px 3px 12px",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{ width: "100%", maxHeight: "65vh", overflowY: "auto" }}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: "0 24px" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function downloadBase64AsFile(base64Data, fileName) {
  const byteCharacters = atob(base64Data);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: "application/octet-stream" });

  // Create a data URI
  const dataUri = URL.createObjectURL(blob);

  // Create a download link
  const downloadLink = document.createElement("a");
  downloadLink.href = dataUri;
  downloadLink.download = fileName;

  // Trigger the download
  downloadLink.click();

  // Clean up the data URI
  URL.revokeObjectURL(dataUri);
}

const Index = ({}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isAgreePrivacy, setIsAgreePrivacy] = useState(false);
  const [onRecruitment, setOnRecruitment] = useState({});
  const [logo, setLogo] = useState("");
  const [isCheck, setIsCheck] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [navDashboard, setNavDashboard] = useState({});
  const [value, setValue] = React.useState(0);

  useEffect(() => {
    fetchData();
    if (!localStorage.getItem("validates")) {
      const validates = [];
      for (let index = 0; index < 8; index++) {
        if (index === 0) validates.push(true);
        else validates.push(false);
      }
      localStorage.setItem("validates", JSON.stringify(validates));
      localStorage.setItem("value", value);
    }
  }, []);

  useEffect(() => {
    const valueIdx = localStorage.getItem("value");
    setValue(parseInt(valueIdx));
  }, [parseInt(localStorage.getItem("value"))]);

  const fetchData = async () => {
    setLoading(true);
    const resBio = await localforage.getItem("bio");
    const resNav = await localforage.getItem("navigation");
    const dashboard = resNav?.filter((n) => n.page === "dashboard");
    setNavDashboard(
      dashboard[0] || {
        title: "My Career",
        link: `/${version}/user/dashboard/my-career`,
      }
    );
    setLogo(resBio.logo);
    const res = await Promise.all([checkAgreePrivacy(), checkOnRecruitment()]);
    setIsAgreePrivacy(res[0]?.data[0]?.is_agree_privacy);
    setOnRecruitment(res[1]?.data[0]);
    setLoading(false);
  };

  const handleChange = (event, newValue) => {
    const validates = JSON.parse(localStorage.getItem("validates"));
    if (validates[newValue]) {
      localStorage.setItem("value", newValue);
      setValue(parseInt(newValue));
    }
  };

  const handleCheck = (e, val) => setIsCheck(val);

  const handleAgree = async () => {
    setLoadingButton(true);
    await updateAgreePrivacy({ is_agree_privacy: isCheck });
    setLoadingButton(false);
    fetchData();
  };

  const handleDownloadFlk = async () => {
    if (loadingButton) {
      return;
    } else {
      const token = await localforage.getItem("token");
      setLoadingButton(true);
      const resDownload = await downloadFLK({
        candidate_id: onRecruitment?.candidate_id,
        token,
      });
      setLoadingButton(false);
      // Usage
      const base64String = resDownload.data; // Replace with your Base64 data
      const fileName = Date.now() + ".pdf"; // Replace with your desired file name
      downloadBase64AsFile(base64String, fileName);
    }
  };

  if (loading) return <CircularProgress />;

  if (onRecruitment?.candidate_id)
    return (
      <Stack>
        <Typography sx={{ fontSize: "20px" }}>
          Anda saat ini sedang dalam tahap rekrutmen untuk posisi{" "}
          <b>
            <i>{onRecruitment?.position_group_name}</i>
          </b>
          . Silakan cek dan/atau update dokumen pribadi Anda di bawah ini.
        </Typography>
        <Stack
          sx={{
            mt: "1rem",
            border: "1px solid #adb5bd",
            borderRadius: "8px",
            p: "1rem 0",
          }}
        >
          <UploadDocument onRecruit={true} />
        </Stack>
        <Stack
          direction="row"
          spacing={2}
          sx={{ mt: "1rem", justifyContent: "center" }}
        >
          <Button
            variant="contained"
            onClick={handleDownloadFlk}
            disabled={loadingButton}
          >
            Download FLK
          </Button>
          <Button
            variant="contained"
            onClick={() => navigate(navDashboard.link)}
          >
            {navDashboard?.title}
          </Button>
        </Stack>
      </Stack>
    );

  return (
    <Grid container maxWidth={"lg"} sx={styleGrid}>
      {isAgreePrivacy ? (
        <Grid item sm={12}>
          <Typography variant="h5" sx={{ mb: "1rem" }}>
            Profile
          </Typography>
          <Box sx={{ flexGrow: 1, display: "flex" }}>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              // onChange={handleChange}
              aria-label="Vertical tabs example"
              sx={{
                borderRight: 1,
                borderColor: "divider",
                ".MuiTab-textColorPrimary": {
                  alignItems: "baseline !important",
                  textTransform: "none !important",
                  textAlign: "left !important",
                },
                ".Mui-selected": {
                  color: "#fff !important",
                  backgroundColor: "#3f51b5",
                  borderRadius: "10px 0 0 10px",
                },
              }}
            >
              <Tab label="Data Diri" {...a11yProps(0)} />
              <Tab label="Data Keluarga" {...a11yProps(1)} />
              <Tab label="Data Pendidikan" {...a11yProps(2)} />
              <Tab label="Pengalaman Organisasi" {...a11yProps(3)} />
              <Tab label="Pengalaman Kerja" {...a11yProps(4)} />
              <Tab label="Pernyataan Pribadi" {...a11yProps(5)} />
              <Tab label="Riwayat Kesehatan" {...a11yProps(6)} />
              <Tab label="Upload Dokumen" {...a11yProps(7)} />
            </Tabs>
            <TabPanel value={value} index={0}>
              <PersonalData handleChange={handleChange} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Family handleChange={handleChange} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Education handleChange={handleChange} />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <Organization handleChange={handleChange} />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <Experience handleChange={handleChange} />
            </TabPanel>
            <TabPanel value={value} index={5}>
              <PersonalInfo handleChange={handleChange} />
            </TabPanel>
            <TabPanel value={value} index={6}>
              <Illness handleChange={handleChange} />
            </TabPanel>
            <TabPanel value={value} index={7}>
              <UploadDocument handleChange={handleChange} />
            </TabPanel>
          </Box>
        </Grid>
      ) : (
        <Grid item sm={12}>
          <Stack direction="row">
            <LoadableImage alt="" src={logo} style={{ width: "68px" }} />
            <Typography
              sx={{ fontSize: "24px", lineHeight: "30px", m: "auto 1rem" }}
            >
              PT. PAMAPERSADA NUSANTARA <br />{" "}
              <span style={{ fontSize: "20px" }}>
                HUMAN CAPITAL & LEARNING DIVISION
              </span>
            </Typography>
          </Stack>

          <Stack
            sx={{
              fontSize: "13px",
              mt: "2rem",
              maxHeight: "56vh",
              overflowY: "auto",
            }}
          >
            <Typography
              sx={{ fontSize: "14px", fontWeight: "bold", mb: "1rem" }}
            >
              Pernyataan Persetujuan Privasi Data / Data Privacy Consent
              Statement
            </Typography>
            <Typography sx={{ fontSize: "inherit", mb: "1rem" }}>
              Terimakasih atas ketertarikan anda untuk berkarir di PT
              Pamapersada Nusantara (“PAMA”) Group. Untuk kelengkapan seluruh
              proses aplikasi anda, anda diminta untuk menyepakati ketentuan
              hukum dibawah ini: <br />
              <i>
                Thank you for your interest in a career within PT Pamapersada
                Nusantara (“PAMA”) Group. In order to complete your application
                please acknowledge the legal message below:
              </i>
            </Typography>
            <Typography sx={{ fontSize: "inherit", mb: "1rem" }}>
              Untuk memberi Anda layanan yang disesuaikan, dan khususnya layanan
              yang terkait dengan satu atau beberapa tawaran pekerjaan yang
              dibutuhkan oleh PAMA dan / atau anak perusahaannya, kami dapat
              meminta Anda untuk memberikan informasi pribadi. Terkait dengan
              layanan ini, Anda akan diminta untuk memberikan informasi yang
              sifatnya wajib dan opsional.
              <br />
              <i>
                To provide you with adapted services, and in particular services
                linked to one or several job offers proposed by PAMA and/or its
                subsidiary companies , we may ask you to provide personal
                information. According to the services you subscribe to, you
                will be asked to provide mandatory and optional information.
              </i>
            </Typography>
            <Typography sx={{ fontSize: "inherit", mb: "1rem" }}>
              PAMA dan / atau anak perusahaannya yang terlibat akan selalu
              menghormati kehidupan pribadi Anda dan melindungi informasi yang
              Anda kirimkan. Secara khusus, informasi pribadi yang dikumpulkan
              ketika melamar ke satu atau beberapa tawaran pekerjaan yang
              diusulkan oleh PAMA dan/atau anak perusahaannya dimaksudkan untuk
              digunakan secara eksklusif oleh PAMA dan/atau anak perusahaannya.
              <br />
              <i>
                PAMA and/or its subsidiary companies are engaged in respecting
                your private life and protecting the information you submit. In
                particular, the personal information collected when applying to
                one or several job offers proposed by PAMA and/or its subsidiary
                companies are intended to be used exclusively by PAMA and/or its
                subsidiary companies.
              </i>
            </Typography>
            <Typography sx={{ fontSize: "inherit", mb: "1rem" }}>
              Anda mengetahui dan menyepakati bahwa selama proses rekrutmen, ada
              kemungkinan bahwa data pribadi Anda ditransfer ke / diakses oleh
              penyedia layanan di luar PAMA khusus dalam perekrutan Anda di
              seluruh Indonesia.
              <br />
              <i>
                you declare to be aware and accept, during the recruitment
                process, it is possible that your personal data are transferred
                to/accessed by service providers outside of the PAMA specialized
                in the recruiting which can be located around Indonesia.
              </i>
            </Typography>
            <Typography sx={{ fontSize: "inherit", mb: "1rem" }}>
              Data pribadi Anda akan digunakan oleh satu atau lebih perekrut
              PAMA atau perekrut eksternal yang khusus ditugaskan oleh PAMA,
              untuk kasus ini, untuk menilai aplikasi Anda, memeriksa referensi
              Anda dan informasi yang telah Anda berikan dan menghubungi Anda.
              <br />
              <i>
                Your personal data will be used by one or more PAMA recruiters
                or external recruiters specifically missioned by PAMA, as the
                case may be, to assess your application, check your references
                and the information you have provided and to contact you.
              </i>
            </Typography>
            <Typography sx={{ fontSize: "inherit", mb: "1rem" }}>
              PAMA mewajibkan semua perusahaan Grup PAMA atau perusahaan
              eksternal yang ditugaskan oleh PAMA dan yang memiliki akses ke
              data pribadi Anda bahwa mereka telah menerapkan langkah-langkah
              keamanan teknis dan terorganisasi yang sesuai sebagaimana
              dinyatakan dalam Peraturan Menteri Komunikasi dan Informatika
              Republik Indonesia No. 20 tahun 2016 dan seluruh perubahannya pada
              perlindungan data pribadi dalam sistem elektronik terhadap
              pemrosesan data pribadi yang tidak sah atau melanggar hukum dan
              terhadap kehilangan atau perusakan yang tidak disengaja dari, atau
              kerusakan pada, data pribadi.
              <br />
              <i>
                PAMA requires all PAMA Group companies or external companies
                missioned by PAMA and which have access to your personal data
                that they have in place appropriate technical and organizational
                security measures as stated in Minister of Communication and
                Informatic of the Republic of Indonesia No. 20 of 2016 and its
                changes on personal data protection in electronic system against
                unauthorized or unlawful processing of personal data and against
                accidental loss or destruction of, or damage to, personal data.
              </i>
            </Typography>
            <Typography sx={{ fontSize: "inherit", mb: "1rem" }}>
              Informasi pribadi Anda akan disimpan selama 3 tahun setelah
              pembaruan terakhir dari informasi Anda di akun yang Anda buat.
              <br />
              <i>
                Your personal information will be kept for a period of 3 year
                following the last update of your information in your created
                account.
              </i>
            </Typography>
            <Typography sx={{ fontSize: "inherit", mb: "1rem" }}>
              Anda memiliki hak untuk mengakses, memperbaiki, memodifikasi, dan
              menghapus informasi pribadi yang Anda berikan kepada kami. Anda
              dapat menerapkan hak Anda untuk mencabut penerimaan Pernyataan
              Persetujuan Privasi Data ini dengan tidak melanjutkan proses
              aplikasi saudara.
              <br />
              <i>
                You have the right to access, correct, modify and erase personal
                information you have given us. You can apply your right revoking
                the acceptance of this Data Privacy Consent Statement by not
                continuing your apllication process.
              </i>
            </Typography>
            <Divider sx={{ mb: "1rem" }} />
            <FormGroup>
              <FormControlLabel
                control={<Checkbox onChange={handleCheck} />}
                label={
                  <Typography sx={{ fontSize: "12px" }}>
                    Sepakat dengan seluruh kebijakan dalam Pernyataan
                    Persetujuan Privasi Data PAMA
                    <br />
                    <i>Agree with PAMA Data Privacy Consent Statement</i>
                  </Typography>
                }
              />
            </FormGroup>
            <Stack
              direction="row"
              spacing={2}
              sx={{ mb: "1rem", justifyContent: "end" }}
            >
              <Button onClick={() => navigate(`/${version}/user`)}>
                Batal
              </Button>
              <Button
                variant="contained"
                disabled={!isCheck}
                onClick={handleAgree}
                startIcon={loadingButton ? <CircularProgress /> : null}
              >
                Saya Setuju
              </Button>
            </Stack>
          </Stack>
        </Grid>
      )}
    </Grid>
  );
};

export default Index;
