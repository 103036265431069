import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Button,
  CircularProgress,
  Stack,
  Grid,
  IconButton,
  Divider,
  Tooltip,
} from "@mui/material";
import CustomInput from "src/components/CustomInput";
import {
  createPersonalInfo,
  getPersonalInfo,
  updatePersonalInfo,
  setSnackbarOpen,
  getRef,
} from "src/redux/actions";
import { CustomAutoComplete } from "src/pages/admin/Setting/EditPanel/ComponentGroup";
import {
  Add,
  Delete,
  Edit,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@mui/icons-material";
import { inputNumberOnly } from "src/functions/inputNumberOnly";
import TableMaterial from "src/components/Tables/TableMaterial";

const sizeAlpha = [
  { value: "S", title: "S" },
  { value: "M", title: "M" },
  { value: "L", title: "L" },
  { value: "XL", title: "XL" },
  { value: "XXL", title: "XXL" },
];

const sizeAlphaPants = [
  { value: "S", title: "S | 40(EU) | 76 cm (Lingkar Pinggang)" },
  { value: "M", title: "M | 42(EU) | 81 cm (Lingkar Pinggang)" },
  { value: "L", title: "L | 44(EU) | 86 cm (Lingkar Pinggang)" },
  { value: "XL", title: "XL | 46(EU) | 91 cm (Lingkar Pinggang)" },
  { value: "XXL", title: "XXL | 48(EU) | 96 cm (Lingkar Pinggang)" },
];

const sizeNumber = [
  { value: "36", title: "36" },
  { value: "37", title: "37" },
  { value: "38", title: "38" },
  { value: "39", title: "39" },
  { value: "40", title: "40" },
  { value: "41", title: "41" },
  { value: "42", title: "42" },
  { value: "43", title: "43" },
  { value: "44", title: "44" },
  { value: "45", title: "45" },
  { value: "46", title: "46" },
];

const refBoolean = [
  { value: "ya", title: "Ya" },
  { value: "tidak", title: "Tidak" },
];

const refBoolean1 = [
  { value: true, title: "Ya" },
  { value: false, title: "Tidak" },
];

const refBoolean2 = [
  { value: true, title: "Bersedia" },
  { value: false, title: "Tidak" },
];

const PersonalInfo = ({ handleChange }) => {
  const [form, setForm] = useState({});
  const [loading, setLoading] = useState(true);
  const [refFieldWPama, setRefFieldWPama] = useState([]);
  const [validate, setValidate] = useState(false);
  const [dtlOutside, setDtlOutside] = useState({});
  const [openOutside, setOpenOutside] = useState("");
  const [validateOts, setValidateOts] = useState(false);
  const [dtlInternal, setDtlInternal] = useState({});
  const [openInternal, setOpenInternal] = useState("");
  const [validateInt, setValidateInt] = useState(false);
  const [validateTxt] = useState("* required");
  const validates = JSON.parse(localStorage.getItem("validates"));
  const val = parseInt(localStorage.getItem("value"));

  useEffect(() => {
    fetchInfo();
  }, []);

  const fetchInfo = async () => {
    setLoading(true);
    const resInfo = await getPersonalInfo();
    const resRef = await getRef({ type: "refFieldWorkPama" });
    setRefFieldWPama(resRef.data);
    setForm(resInfo.data[0] || {});
    setLoading(false);
  };

  const handleChangeInput = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };
  const checkRequirement = () => {
    if (
      !form.hobby ||
      !form.strength ||
      !form.weakness ||
      !form.field_work_pama_ids ||
      !form.salary_expectations ||
      !form.expected_benefits ||
      form.is_internal_reference === undefined ||
      form.is_internal_reference === null ||
      (form.is_internal_reference === true &&
        form.internal_references?.length === 0) ||
      (form.is_internal_reference === true &&
        form.internal_references === null) ||
      // (form.is_internal_reference && !form.internal_reference) ||
      form.is_job_site === undefined ||
      form.is_job_site === null ||
      // !form.outside_reference ||
      form.on_internal_recruitment === undefined ||
      form.on_internal_recruitment === null ||
      (form.on_internal_recruitment === "ya" &&
        !form.on_internal_recruitment_stage) ||
      form.on_outside_recruitment === undefined ||
      form.on_outside_recruitment === null ||
      (form.on_outside_recruitment === "ya" &&
        !form.on_outside_recruitment_stage) ||
      form.on_astra_recruitment === undefined ||
      form.on_astra_recruitment === null ||
      (form.on_astra_recruitment === "ya" &&
        !form.on_astra_recruitment_stage) ||
      form.on_astra_active === undefined ||
      form.on_astra_active === null ||
      (form.on_astra_active === "ya" && !form.company_name_astra) ||
      !form.shirt_size ||
      !form.pants_size ||
      !form.shoe_size ||
      form.is_outside_reference === null ||
      (form.is_outside_reference === true &&
        form.outside_references?.length === 0) ||
      (form.is_outside_reference === true && form.outside_references === null)
    ) {
      setValidate(true);
      setSnackbarOpen("error", "Terdapat field yang masih kosong.");
      return true;
    } else {
      setValidate(false);
      return false;
    }
  };

  const handleSave = async () => {
    if (checkRequirement()) return;
    setValidate(false);
    let res;
    if (!form.bio_personal_id) {
      res = await createPersonalInfo(form);
    } else {
      res = await updatePersonalInfo(form);
    }
    if (res.code === 200) {
      validates[val + 1] = true;
      localStorage.setItem("validates", JSON.stringify(validates));
      setSnackbarOpen("success", "Data disimpan");
    } else {
      setSnackbarOpen(
        "error",
        "Data belum berhasil disimpan, mohon dicoba kembali."
      );
    }
    setForm({});
    fetchInfo();
  };

  const handleSelectAuto = async (val, key) => {
    if (key === "field_work_pama_id") {
      setForm({ ...form, [key]: val.field_work_pama_id });
    } else {
      setForm({ ...form, [key]: val.value });
    }
  };

  const handleSelectAutoMulti = async (val, key) => {
    const newArr = [];
    // limit 3 data aja
    const newVal = val.slice(0, 3);
    if (newVal) newArr.push(newVal);
    setForm({ ...form, [key]: newVal });
  };

  // ------------- outside ---------
  const handleChangeOutside = (e) => {
    setDtlOutside({ ...dtlOutside, [e.target.name]: e.target.value });
  };

  const handleSaveOutside = () => {
    if (
      !dtlOutside.fullname ||
      !dtlOutside.institute ||
      !dtlOutside.relationship ||
      !dtlOutside.phone_number ||
      !dtlOutside.position
    ) {
      setValidateOts(true);
      setSnackbarOpen("error", "Terdapat field yang masih kosong.");
      return true;
    }
    const copyOts = [...(form?.outside_references || [])];
    if (openOutside === "add") {
      dtlOutside.id = Date.now();
      copyOts.push(dtlOutside);
    } else {
      let index = copyOts.findIndex((obj) => obj.id === dtlOutside.id);
      if (index !== -1) {
        copyOts[index] = dtlOutside;
      } else {
        console.log("Object with the specified ID not found");
      }
    }
    setForm({ ...form, outside_references: copyOts });
    setOpenOutside("");
  };

  const handleDeleteOts = (d) => {
    const copyOts = [...(form?.outside_references || [])];
    let index = copyOts.findIndex((obj) => obj.id === d?.id);

    if (index !== -1) {
      copyOts.splice(index, 1);
    } else {
      console.log("Object with the specified ID not found");
    }
    setForm({ ...form, outside_references: copyOts });
  };

  // ------------- internal ---------
  const handleChangeInternal = (e) => {
    setDtlInternal({ ...dtlInternal, [e.target.name]: e.target.value });
  };

  const handleSaveInternal = () => {
    if (
      !dtlInternal.fullname ||
      !dtlInternal.relationship ||
      !dtlInternal.phone_number ||
      !dtlInternal.position
    ) {
      setValidateInt(true);
      setSnackbarOpen("error", "Terdapat field yang masih kosong.");
      return true;
    }
    const copyInt = [...(form?.internal_references || [])];
    if (openInternal === "add") {
      dtlInternal.id = Date.now();
      copyInt.push(dtlInternal);
    } else {
      let index = copyInt.findIndex((obj) => obj.id === dtlInternal.id);
      if (index !== -1) {
        copyInt[index] = dtlInternal;
      } else {
        console.log("Object with the specified ID not found");
      }
    }
    setForm({ ...form, internal_references: copyInt });
    setOpenInternal("");
  };

  const handleDeleteInt = (d) => {
    const copyInt = [...(form?.internal_references || [])];
    let index = copyInt.findIndex((obj) => obj.id === d?.id);

    if (index !== -1) {
      copyInt.splice(index, 1);
    } else {
      console.log("Object with the specified ID not found");
    }
    setForm({ ...form, internal_references: copyInt });
  };

  if (loading) return <CircularProgress />;

  return (
    <Container>
      <Typography sx={{ fontSize: "24px", mb: 2 }}>
        <b>Pernyataan Pribadi</b>
      </Typography>
      <CustomInput
        label="Hobi"
        name="hobby"
        onChange={handleChangeInput}
        value={form.hobby}
        error={validate && !form.hobby}
        helpertext={validate && !form.hobby && validateTxt}
      />
      <CustomInput
        label="Kekuatan Diri"
        multiline
        rows={4}
        name="strength"
        onChange={handleChangeInput}
        value={form.strength}
        error={validate && !form.strength}
        helpertext={validate && !form.strength && validateTxt}
      />
      <CustomInput
        label="Kelemahan Diri"
        multiline
        rows={4}
        name="weakness"
        onChange={handleChangeInput}
        value={form.weakness}
        error={validate && !form.weakness}
        helpertext={validate && !form.weakness && validateTxt}
      />
      {/* <CustomInput label="Bidang Kerja yang Diminati" /> */}
      <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
        Bidang Kerja yang Diminati {` (maksimal 3 bidang)`}
      </Typography>
      <CustomAutoComplete
        name="field_work_pama_ids"
        optionsArr={refFieldWPama}
        keyLabel={"field_work_pama_name"}
        handleSelect={handleSelectAutoMulti}
        style={{ marginBottom: "1rem" }}
        value={form.field_work_pama_ids || []}
        error={validate && !form.field_work_pama_ids}
        helpertext={validate && !form.field_work_pama_ids && validateTxt}
        multiple
      />
      {/* <CustomAutoComplete
        name="field_work_pama_id"
        optionsArr={refFieldWPama}
        keyLabel={"field_work_pama_name"}
        handleSelect={handleSelectAuto}
        
        style={{ marginBottom: "1rem" }}
        value={
          refFieldWPama.filter(
            (r) => r.field_work_pama_id === form.field_work_pama_id
          )[0]
        }
      /> */}
      <CustomInput
        label="Ekspektasi Gaji"
        name="salary_expectations"
        onChange={handleChangeInput}
        value={form.salary_expectations}
        error={validate && !form.salary_expectations}
        helpertext={validate && !form.salary_expectations && validateTxt}
        onKeyDown={inputNumberOnly}
      />
      <CustomInput
        label="Fasilitas/Benefit yang Diharapkan"
        multiline
        rows={4}
        name="expected_benefits"
        onChange={handleChangeInput}
        value={form.expected_benefits}
        error={validate && !form.expected_benefits}
        helpertext={validate && !form.expected_benefits && validateTxt}
      />
      <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
        {`Apakah Anda bersedia ditempatkan di jobsite (remote area)?`}
      </Typography>
      <CustomAutoComplete
        name="is_job_site"
        optionsArr={refBoolean2}
        keyLabel={"title"}
        handleSelect={handleSelectAuto}
        value={refBoolean2.filter((b) => b.value === form?.is_job_site)[0]}
        style={{ marginBottom: "1rem" }}
        error={validate && !form.is_job_site}
        helpertext={validate && !form.is_job_site && validateTxt}
      />
      <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
        Apakah Anda memiliki kerabat yang bekerja di PT Pamapersada Nusantara?
      </Typography>
      <CustomAutoComplete
        name="is_internal_reference"
        optionsArr={refBoolean1}
        keyLabel={"title"}
        handleSelect={handleSelectAuto}
        value={
          refBoolean1.filter((b) => b.value === form?.is_internal_reference)[0]
        }
        style={{ marginBottom: "1rem" }}
        error={validate && form.is_internal_reference === undefined}
        helpertext={
          validate && form.is_internal_reference === undefined && validateTxt
        }
      />
      {openInternal && (
        <Stack
          sx={{
            p: "1rem",
            border: "1px solid #B5B9E3",
            borderRadius: "8px",
            backgroundColor: "#F0F1F9",
            mb: "1rem",
          }}
        >
          <CustomInput
            label="Nama"
            name="fullname"
            onChange={handleChangeInternal}
            value={dtlInternal.fullname}
            error={validateInt && !dtlInternal.fullname}
            // helpertext={validate && !dtlInternal.internal_reference && validateTxt}
          />
          <CustomInput
            label="Hubungan"
            name="relationship"
            onChange={handleChangeInternal}
            value={dtlInternal.relationship}
            error={validateInt && !dtlInternal.relationship}
            // helpertext={validate && !dtlInternal.internal_reference && validateTxt}
          />
          <CustomInput
            label="No HP"
            name="phone_number"
            onChange={handleChangeInternal}
            value={dtlInternal.phone_number}
            error={validateInt && !dtlInternal.phone_number}
            // helpertext={validate && !dtlInternal.internal_reference && validateTxt}
          />
          <CustomInput
            label="Jabatan"
            name="position"
            onChange={handleChangeInternal}
            value={dtlInternal.position}
            error={validateInt && !dtlInternal.position}
            // helpertext={validate && !dtlInternal.internal_reference && validateTxt}
          />

          <Stack direction="row" spacing={2}>
            <Button variant="outlined" onClick={() => setOpenInternal("")}>
              Batal
            </Button>
            <Button variant="contained" onClick={handleSaveInternal}>
              Simpan
            </Button>
          </Stack>
        </Stack>
      )}

      {!openInternal && form.is_internal_reference === true && (
        <Grid
          container
          sx={{
            p: "1rem",
            border: "1px solid",
            borderRadius: "8px",
            mb: "1rem",
          }}
        >
          <Grid item xs={8}>
            <Typography sx={{ fontWeight: "600" }}>
              Data Kerabat bekerja di PT Pamapersada Nusantara
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "end" }}>
            <Tooltip title="Tambah Referensi">
              <IconButton
                onClick={() => {
                  setDtlInternal({});
                  setOpenInternal("add");
                }}
              >
                <Add />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12 / 4}>
            <Typography
              sx={{ fontWeight: "500", fontSize: "14px" }}
            >{`Nama & Hubungan`}</Typography>
          </Grid>
          <Grid item xs={12 / 4}>
            <Typography
              sx={{ fontWeight: "500", fontSize: "14px" }}
            >{`No HP`}</Typography>
          </Grid>
          <Grid item xs={12 / 4}>
            <Typography
              sx={{ fontWeight: "500", fontSize: "14px" }}
            >{`Jabatan`}</Typography>
          </Grid>
          <Grid item xs={12 / 4}>
            <Typography
              sx={{ fontWeight: "500", fontSize: "14px" }}
            >{`Actions`}</Typography>
          </Grid>
          <Grid item xs={12} sx={{ mb: "0.5rem" }}>
            <Divider />
          </Grid>
          {form?.internal_references?.map((o) => (
            <>
              <Grid item xs={12 / 4} sx={{ m: "auto" }}>
                <Typography sx={{ fontSize: "13px", lineHeight: "20px" }}>
                  {o?.fullname} <br /> {`(${o?.relationship})`}
                </Typography>
              </Grid>
              <Grid item xs={12 / 4} sx={{ m: "auto" }}>
                <Typography sx={{ fontSize: "13px", lineHeight: "20px" }}>
                  {o?.phone_number}
                </Typography>
              </Grid>
              <Grid item xs={12 / 4} sx={{ m: "auto" }}>
                <Typography sx={{ fontSize: "13px", lineHeight: "20px" }}>
                  {o?.position}
                </Typography>
              </Grid>
              <Grid item xs={12 / 4} sx={{ m: "auto" }}>
                <Stack direction="row">
                  <IconButton
                    onClick={() => {
                      setDtlInternal(o);
                      setOpenInternal("edit");
                    }}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteInt(o)}>
                    <Delete />
                  </IconButton>
                </Stack>
              </Grid>
            </>
          ))}

          {!form?.internal_references?.length ? (
            <Grid
              item
              xs={12}
              sx={{ textAlign: "center", fontStyle: "italic" }}
            >
              <Typography
                sx={{
                  color:
                    validate &&
                    (form.internal_references === null ||
                      form.internal_references?.length <= 1)
                      ? "red !important"
                      : "inherit",
                }}
              >
                Data belum tersedia
              </Typography>
            </Grid>
          ) : null}
        </Grid>
      )}
      {/* {form?.is_internal_reference && (
        <CustomInput
          label="Sebutkan kerabat yang bekerja di PT Pamapersada Nusantara (Nama & Jabatan)"
          name="internal_reference"
          onChange={handleChangeInput}
          value={form.internal_reference}
          error={validate && !form.internal_reference}
          helpertext={validate && !form.internal_reference && validateTxt}
        />
      )} */}

      {/* <CustomInput
        label="Referensi di Luar PT. Pamapersada Nusantara"
        name="outside_reference"
        onChange={handleChangeInput}
        value={form.outside_reference}
        error={validate && !form.outside_reference}
        helpertext={validate && !form.outside_reference && validateTxt}
      /> */}
      <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
        Apakah memiliki referensi di luar PT. Pamapersada Nusantara?
      </Typography>
      <CustomAutoComplete
        name="is_outside_reference"
        optionsArr={refBoolean1}
        keyLabel={"title"}
        handleSelect={handleSelectAuto}
        value={
          refBoolean1.filter((b) => b.value === form?.is_outside_reference)[0]
        }
        style={{ marginBottom: "1rem" }}
        error={
          validate &&
          (form.is_outside_reference === undefined ||
            form.is_outside_reference === null)
        }
        helpertext={
          validate &&
          (form.is_outside_reference === undefined ||
            form.is_outside_reference === null) &&
          validateTxt
        }
      />
      {openOutside && (
        <Stack
          sx={{
            p: "1rem",
            border: "1px solid #B5B9E3",
            borderRadius: "8px",
            backgroundColor: "#F0F1F9",
            mb: "1rem",
          }}
        >
          <CustomInput
            label="Nama"
            name="fullname"
            onChange={handleChangeOutside}
            value={dtlOutside.fullname}
            error={validateOts && !dtlOutside.fullname}
            // helpertext={validate && !dtlOutside.outside_reference && validateTxt}
          />
          <CustomInput
            label="Instansi/Organisasi/Perusahaan"
            name="institute"
            onChange={handleChangeOutside}
            value={dtlOutside.institute}
            error={validateOts && !dtlOutside.institute}
            // helpertext={validate && !dtlOutside.outside_reference && validateTxt}
          />
          <CustomInput
            label="Hubungan"
            name="relationship"
            onChange={handleChangeOutside}
            value={dtlOutside.relationship}
            error={validateOts && !dtlOutside.relationship}
            // helpertext={validate && !dtlOutside.outside_reference && validateTxt}
          />
          <CustomInput
            label="No HP"
            name="phone_number"
            onChange={handleChangeOutside}
            value={dtlOutside.phone_number}
            error={validateOts && !dtlOutside.phone_number}
            // helpertext={validate && !dtlOutside.outside_reference && validateTxt}
          />
          <CustomInput
            label="Jabatan"
            name="position"
            onChange={handleChangeOutside}
            value={dtlOutside.position}
            error={validateOts && !dtlOutside.position}
            // helpertext={validate && !dtlOutside.outside_reference && validateTxt}
          />

          <Stack direction="row" spacing={2}>
            <Button variant="outlined" onClick={() => setOpenOutside("")}>
              Batal
            </Button>
            <Button variant="contained" onClick={handleSaveOutside}>
              Simpan
            </Button>
          </Stack>
        </Stack>
      )}

      {!openOutside && form.is_outside_reference === true && (
        <Grid
          container
          sx={{
            p: "1rem",
            border: "1px solid",
            borderRadius: "8px",
            mb: "1rem",
          }}
        >
          <Grid item xs={8}>
            <Typography sx={{ fontWeight: "600" }}>
              Data referensi di luar PT Pamapersada Nusantara
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "end" }}>
            <Tooltip title="Tambah Referensi">
              <IconButton
                onClick={() => {
                  setDtlOutside({});
                  setOpenOutside("add");
                }}
              >
                <Add />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12 / 5}>
            <Typography
              sx={{ fontWeight: "500", fontSize: "14px" }}
            >{`Nama & Hubungan`}</Typography>
          </Grid>
          <Grid item xs={12 / 5}>
            <Typography
              sx={{ fontWeight: "500", fontSize: "14px" }}
            >{`Instansi`}</Typography>
          </Grid>
          <Grid item xs={12 / 5}>
            <Typography
              sx={{ fontWeight: "500", fontSize: "14px" }}
            >{`No HP`}</Typography>
          </Grid>
          <Grid item xs={12 / 5}>
            <Typography
              sx={{ fontWeight: "500", fontSize: "14px" }}
            >{`Jabatan`}</Typography>
          </Grid>
          <Grid item xs={12 / 5}>
            <Typography
              sx={{ fontWeight: "500", fontSize: "14px" }}
            >{`Actions`}</Typography>
          </Grid>
          <Grid item xs={12} sx={{ mb: "0.5rem" }}>
            <Divider />
          </Grid>
          {form?.outside_references?.map((o) => (
            <>
              <Grid item xs={12 / 5} sx={{ m: "auto" }}>
                <Typography sx={{ fontSize: "13px", lineHeight: "20px" }}>
                  {o?.fullname} <br /> {`(${o?.relationship})`}
                </Typography>
              </Grid>
              <Grid item xs={12 / 5} sx={{ m: "auto" }}>
                <Typography sx={{ fontSize: "13px", lineHeight: "20px" }}>
                  {o?.institute}
                </Typography>
              </Grid>
              <Grid item xs={12 / 5} sx={{ m: "auto" }}>
                <Typography sx={{ fontSize: "13px", lineHeight: "20px" }}>
                  {o?.phone_number}
                </Typography>
              </Grid>
              <Grid item xs={12 / 5} sx={{ m: "auto" }}>
                <Typography sx={{ fontSize: "13px", lineHeight: "20px" }}>
                  {o?.position}
                </Typography>
              </Grid>
              <Grid item xs={12 / 5} sx={{ m: "auto" }}>
                <Stack direction="row">
                  <IconButton
                    onClick={() => {
                      setDtlOutside(o);
                      setOpenOutside("edit");
                    }}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteOts(o)}>
                    <Delete />
                  </IconButton>
                </Stack>
              </Grid>
            </>
          ))}

          {!form?.outside_references?.length ? (
            <Grid
              item
              xs={12}
              sx={{ textAlign: "center", fontStyle: "italic" }}
            >
              <Typography
                sx={{
                  color:
                    validate &&
                    (form.outside_references === null ||
                      form.outside_references?.length <= 1)
                      ? "red !important"
                      : "inherit",
                }}
              >
                Data belum tersedia
              </Typography>
            </Grid>
          ) : null}
        </Grid>
      )}
      {/* <TableMaterial
        title="Referensi di luar PT. Pamapersada Nusantara"
        columns={[
          { title: "Nama", field: "fullname" },
          { title: "Instansi/Organisasi/Perusahaan", field: "institute" },
          { title: "Hubungan", field: "relationship" },
          { title: "No HP", field: "phone_number" },
          { title: "Jabatan", field: "position" },
        ]}
        data={form?.outside_references || []}
        options={{
          pageSizeOptions: [5, 10, 20],
          showFirstLastPageButtons: false,
          actionsColumnIndex: -1,
          pageSize: 5,
          emptyRowsWhenPaging: false,
          search: false,
        }}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve, reject) => {
              console.log(newData);
              // onRowChange(newData, null, "add", resolve);
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              // onRowChange(newData, oldData, "update", resolve);
            }),
          onRowDelete: (oldData) =>
            new Promise((resolve, reject) => {
              // onRowChange(null, oldData, "delete", resolve);
            }),
        }}
      /> */}
      <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
        Apakah Anda sedang mengikuti proses rekrutmen di PAMA Grup?
      </Typography>
      <CustomAutoComplete
        name="on_internal_recruitment"
        optionsArr={refBoolean}
        keyLabel={"title"}
        handleSelect={handleSelectAuto}
        value={
          refBoolean.filter((b) => b.value === form?.on_internal_recruitment)[0]
        }
        style={{ marginBottom: "1rem" }}
        error={validate && form.on_internal_recruitment === undefined}
        helpertext={
          validate && form.on_internal_recruitment === undefined && validateTxt
        }
      />
      {form.on_internal_recruitment === "ya" && (
        <CustomInput
          label="Tuliskan Nama Perusahaan yang Dimaksud dan progres Rekrutmen/Seleksi Terakhir"
          name="on_internal_recruitment_stage"
          onChange={handleChangeInput}
          value={form.on_internal_recruitment_stage}
          multiline
          rows={2}
          error={validate && !form.on_internal_recruitment_stage}
          helpertext={
            validate && !form.on_internal_recruitment_stage && validateTxt
          }
        />
      )}
      <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
        Apakah Anda sedang mengikuti proses rekrutmen di Astra Grup?
      </Typography>
      <CustomAutoComplete
        name="on_astra_recruitment"
        optionsArr={refBoolean}
        keyLabel={"title"}
        handleSelect={handleSelectAuto}
        value={
          refBoolean.filter((b) => b.value === form?.on_astra_recruitment)[0]
        }
        style={{ marginBottom: "1rem" }}
        error={
          validate &&
          (form.on_astra_recruitment === undefined ||
            form.on_astra_recruitment === null)
        }
        helpertext={
          validate && form.on_astra_recruitment === undefined && validateTxt
        }
      />
      {form.on_astra_recruitment === "ya" && (
        <CustomInput
          label="Tuliskan Nama Perusahaan Astra Grup dan Progress Rekrutmen/Seleksi Terakhir"
          name="on_astra_recruitment_stage"
          onChange={handleChangeInput}
          value={form.on_astra_recruitment_stage}
          multiline
          rows={2}
          error={validate && !form.on_astra_recruitment_stage}
          helpertext={
            validate && !form.on_astra_recruitment_stage && validateTxt
          }
        />
      )}
      <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
        Apakah Anda sedang mengikuti proses rekrutmen di perusahaan lain?
      </Typography>
      <CustomAutoComplete
        name="on_outside_recruitment"
        optionsArr={refBoolean}
        keyLabel={"title"}
        handleSelect={handleSelectAuto}
        value={
          refBoolean.filter((b) => b.value === form?.on_outside_recruitment)[0]
        }
        style={{ marginBottom: "1rem" }}
        error={validate && form.on_outside_recruitment === undefined}
        helpertext={
          validate && form.on_outside_recruitment === undefined && validateTxt
        }
      />
      {form.on_outside_recruitment === "ya" && (
        <CustomInput
          label="Tuliskan Nama Perusahaan dan Progress Rekrutmen/Seleksi Terakhir"
          name="on_outside_recruitment_stage"
          onChange={handleChangeInput}
          value={form.on_outside_recruitment_stage}
          multiline
          rows={2}
          error={validate && !form.on_outside_recruitment_stage}
          helpertext={
            validate && !form.on_outside_recruitment_stage && validateTxt
          }
        />
      )}
      <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
        Apakah Anda karyawan aktif perusahaan yang tergabung Astra Group?
      </Typography>
      <CustomAutoComplete
        name="on_astra_active"
        optionsArr={refBoolean}
        keyLabel={"title"}
        handleSelect={handleSelectAuto}
        value={refBoolean.filter((b) => b.value === form?.on_astra_active)[0]}
        style={{ marginBottom: "1rem" }}
        error={validate && form.on_astra_active === undefined}
        helpertext={
          validate && form.on_astra_active === undefined && validateTxt
        }
      />
      {form.on_astra_active === "ya" && (
        <CustomInput
          label="Tuliskan Nama Perusahaan Astra Group yang dimaksud"
          name="company_name_astra"
          onChange={handleChangeInput}
          value={form.company_name_astra}
          error={validate && !form.company_name_astra}
          helpertext={validate && !form.company_name_astra && validateTxt}
        />
      )}
      <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
        Ukuran Baju
      </Typography>
      <CustomAutoComplete
        name="shirt_size"
        optionsArr={sizeAlpha}
        keyLabel={"title"}
        handleSelect={handleSelectAuto}
        value={sizeAlpha.filter((sz) => sz.value === form?.shirt_size)[0]}
        style={{ marginBottom: "1rem" }}
        error={validate && !form.shirt_size}
        helpertext={validate && !form.shirt_size && validateTxt}
      />
      <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
        Ukuran Celana
      </Typography>
      <CustomAutoComplete
        name="pants_size"
        optionsArr={sizeAlphaPants}
        keyLabel={"title"}
        handleSelect={handleSelectAuto}
        value={sizeAlphaPants.filter((sz) => sz.value === form?.pants_size)[0]}
        style={{ marginBottom: "1rem" }}
        error={validate && !form.pants_size}
        helpertext={validate && !form.pants_size && validateTxt}
      />
      <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
        Ukuran Sepatu
      </Typography>
      <CustomAutoComplete
        name="shoe_size"
        optionsArr={sizeNumber}
        keyLabel={"title"}
        handleSelect={handleSelectAuto}
        value={sizeNumber.filter((sz) => sz.value === form?.shoe_size)[0]}
        style={{ marginBottom: "1rem" }}
        error={validate && !form.shoe_size}
        helpertext={validate && !form.shoe_size && validateTxt}
      />
      {/* <CustomInput label="Riwayat Penyakit Berat" multiline rows={3} />
      <CustomInput label="Riwayat Penyakit Kambuhan" multiline rows={3} /> */}

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: "100%", my: "1.5rem" }}
      >
        <Button
          variant="outlined"
          sx={{
            border: "none",
            opacity: validates[val - 1] ? 1 : 0,
          }}
          onClick={(e) => handleChange(e, val - 1)}
          disabled={!validates[val - 1]}
          startIcon={<KeyboardArrowLeft />}
        >
          Pengalaman Kerja
        </Button>
        <Button variant="contained" onClick={handleSave}>
          Simpan
        </Button>
        <Button
          variant="outlined"
          sx={{
            border: "none",
            opacity: validates[val + 1] ? 1 : 0,
          }}
          onClick={(e) => {
            if (checkRequirement()) return;
            handleChange(e, val + 1);
          }}
          disabled={!validates[val + 1]}
          endIcon={<KeyboardArrowRight />}
        >
          Riwayat Kesehatan
        </Button>
      </Stack>
    </Container>
  );
};

export default PersonalInfo;
