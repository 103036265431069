import {
  SET_NAV_PREVIEW,
  SET_LOGO_PREVIEW,
  SET_THEME_PREVIEW,
  SET_CONTENT_PREVIEW,
  SET_IS_PREVIEW,
  SET_CONTENT_HOVER_ID,
  SET_PAGE_PREVIEW,
  SET_ARTICLE_PREVIEW,
  SET_TESTI_PREVIEW,
  SET_GAL_PREVIEW,
  SET_BTN_PREVIEW,
  SET_IS_CHANGE,
  SET_HEIGHT_HEADER,
  SET_BACKDROP,
  SET_NAV_CHANGED,
} from "../events";
import store from "../store";
import axiosGlobal from "../../axios-global";

const { dispatch } = store;

export const setHeightHeader = (height) => {
  dispatch({
    type: SET_HEIGHT_HEADER,
    payload: height,
  });
};

export const setNavPreview = (nav) => {
  dispatch({
    type: SET_NAV_PREVIEW,
    payload: nav,
  });
};

export const setLogoPreview = (logo) => {
  dispatch({
    type: SET_LOGO_PREVIEW,
    payload: logo,
  });
};

export const setThemePreview = (theme) => {
  dispatch({
    type: SET_THEME_PREVIEW,
    payload: theme,
  });
};

export const setContentPreview = (content) => {
  dispatch({
    type: SET_CONTENT_PREVIEW,
    payload: content,
  });
};

export const setIsPreview = (isPreview) => {
  dispatch({
    type: SET_IS_PREVIEW,
    payload: isPreview,
  });
};

export const setContentHoverId = (contentHoverId) => {
  dispatch({
    type: SET_CONTENT_HOVER_ID,
    payload: contentHoverId,
  });
};

export const setPagePreview = (pagePreview) => {
  dispatch({
    type: SET_PAGE_PREVIEW,
    payload: pagePreview,
  });
};

export const setNavChanged = (navChanged) => {
  dispatch({
    type: SET_NAV_CHANGED,
    payload: navChanged,
  });
};

export const setBackdrop = (backdrop) => {
  dispatch({
    type: SET_BACKDROP,
    payload: backdrop,
  });
};

export const setArticlePreview = (articlePreview) => {
  dispatch({
    type: SET_ARTICLE_PREVIEW,
    payload: articlePreview,
  });
};

export const setTestiPreview = (testiPreview) => {
  dispatch({
    type: SET_TESTI_PREVIEW,
    payload: testiPreview,
  });
};

export const setGalPreview = (galPreview) => {
  dispatch({
    type: SET_GAL_PREVIEW,
    payload: galPreview,
  });
};

export const setBtnPreview = (btnPreview) => {
  dispatch({
    type: SET_BTN_PREVIEW,
    payload: btnPreview,
  });
};

export const setIsChange = (isChange, param) => {
  const isChangeObj = {};
  isChangeObj[param] = isChange;
  dispatch({ type: SET_IS_CHANGE, payload: isChangeObj });
};

export const uploadFoto = async (formData) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios({
      method: "post",
      url: `/upload/landing/image`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    });
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const uploadFileJp = async (file, old_file) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("old_file", old_file);
    const axios = await axiosGlobal();
    const data = await axios.post("/upload", formData);
    if (data) {
      return data.data;
    }
  } catch (err) {
    // kondisi kalau error upload
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = err.response.data;
    const preElement = tempDiv.querySelector("pre");
    if (preElement) {
      const textInsidePre = preElement.textContent || preElement.innerText;
      return textInsidePre;
    } else {
      return err.response.data;
    }
  }
};

export const uploadKtpJp = async (file) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    const axios = await axiosGlobal();
    const data = await axios.post("/upload-ktp", formData);
    if (data) {
      return data.data;
    }
  } catch (err) {
    // kondisi kalau error upload
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = err.response.data;
    const preElement = tempDiv.querySelector("pre");
    if (preElement) {
      const textInsidePre = preElement.textContent || preElement.innerText;
      return textInsidePre;
    } else {
      return err.response.data;
    }
  }
};
