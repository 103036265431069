import { SET_SNACKBAR_OPEN, SET_SNACKBAR_CLOSE } from "../events";
import store from "../store";
import axiosGlobal from "../../axios-global";
import localforage from "localforage";
import { menu, arr, domain } from "src/components/Layout/dummy";
import { setHeightHeader } from "./preview-actions";

const { dispatch } = store;

export const getInfoInstitution = async () => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post("/info/institution", {
      domain,
    });
    if (data) {
      const res = data.data.data[0];
      await localforage.setItem("bio", res);
      await localforage.setItem("navigation", res.header_menu);
      await localforage.setItem("theme", res.theme);
      await localforage.setItem("data", res.content);
      await localforage.setItem("faq", res.faq);

      createTempDataAsideLanding(res.content);
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

const createTempDataAsideLanding = async (dataLanding) => {
  let tempData = [];
  const copyData = dataLanding;
  const { id, type, sectCn, visibility, children } = copyData[0];
  const headerSect = {
    id,
    type,
    sectCn,
    visibility,
    children,
    props: {},
  };
  headerSect.children = children.filter((child) => child.colCn);

  const footerSect = copyData.slice(-1)[0];
  footerSect.sectProps = { mt: "auto", backgroundColor: "#ffff" };

  const height = headerSect.children[0].children[0].children[0].props.height;
  setHeightHeader(height);

  tempData.push(headerSect);
  tempData.push(footerSect);
  await localforage.setItem("tempData", tempData);
};

export const updateInfoInstitution = async (type, obj) => {
  try {
    const axios = await axiosGlobal();
    let data = null;
    if (type === "logo") {
      data = await axios({
        method: "post",
        url: `/update/landing/${type}`,
        data: obj,
        headers: { "Content-Type": "multipart/form-data" },
      });
    } else {
      data = await axios.post(`/update/landing/${type}`, obj);
    }

    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const customPageAPI = async (typeReq, type, obj) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/${typeReq}/lp/${type}`, obj);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const galleryAPI = async (type, params) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/gallery/${type}`, params);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const testimonyAPI = async (type, params) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/testimony/${type}`, params);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const articleAPI = async (type, params) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/article/${type}`, params);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const getPublish = async (token) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post("/landing/publish/get", { domain, token });
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const getJobs = async (params) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post("/get/job-posting", params);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const myJobs = async (params) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post("/get/my-jobs", params);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const getJob = async (jobPostingId) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post("/get/job-posting/" + jobPostingId);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err.response);
    return err.response.data;
  }
};

export const candidateCheckApply = async (params) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post("/candidate/check", params);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const candidateApplyJob = async (params) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post("/candidate/apply", params);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const userApplyJob = async (params) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post("/user/apply", params);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response.data;
  }
};

export const enrollCourse = async (params) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post("/landing/publish/enroll", params);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const paidChecker = async (params) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post("/paid/checker", params);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const getTokenPayment = async (params) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post("/payment/tokenOnly", params);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const getRefZip = async (code) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/reference/zipcode/${code}`);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const getRefSchool = async (params) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/reference/school`, params);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const getRefRegion = async (params) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/reference/region`, params);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const getRefEdu = async () => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/reference/educational/stage`);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const refTags = async (type, params) => {
  const url = type === "get" ? "/reference/tags/get" : `/reference/tag/${type}`;
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(url, params);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const getBiodata = async () => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/get/biodata`);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const updateBiodata = async (params) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/update/biodata`, params);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const createFamily = async (params) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/create/family`, params);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const getFamily = async () => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/get/family`);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const updateFamily = async (params) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/update/family`, params);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const deleteFamily = async (params) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/delete/family`, params);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const createEducation = async (params) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/create/education`, params);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const getEducation = async (params) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/get/education`, params);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const updateEducation = async (params) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/update/education`, params);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const deleteEducation = async (params) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/delete/education`, params);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const createInformalEducation = async (params) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/create/informal/education`, params);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const getInformalEducation = async () => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/get/informal/education`);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const updateInformalEducation = async (params) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/update/informal/education`, params);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const deleteInformalEducation = async (params) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/delete/informal/education`, params);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const createOrganization = async (params) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/create/organization`, params);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const getOrganization = async () => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/get/organization`);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const updateOrganization = async (params) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/update/organization`, params);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const deleteOrganization = async (params) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/delete/organization`, params);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const createExperience = async (params) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/create/experience`, params);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const getExperience = async () => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/get/experience`);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const updateExperience = async (params) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/update/experience`, params);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const deleteExperience = async (params) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/delete/experience`, params);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const createPersonalInfo = async (params) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/create/personal/info`, params);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const getPersonalInfo = async () => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/get/personal/info`);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const updatePersonalInfo = async (params) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/update/personal/info`, params);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const deletePersonalInfo = async (params) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/delete/personal/info`, params);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const checkAgreePrivacy = async (params) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/check-agree-privacy`, params);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const checkOnRecruitment = async (params) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/check-on-recruitment`, params);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const updateAgreePrivacy = async (params) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/update-agree-privacy`, params);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const createPersonalDocument = async (params) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/create/personal/document`, params);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const getPersonalDocument = async () => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/get/personal/document`);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const updatePersonalDocument = async (params) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/update/personal/document`, params);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const deletePersonalDocument = async (params) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/delete/personal/document`, params);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const refFamilyStatus = async () => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/reference/family/status`);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const refMaritalStatus = async () => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/reference/marital/status`);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const refEthnic = async () => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/reference/ethnic`);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const refCountries = async () => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/reference/countries`);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const refReligion = async () => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/reference/religion`);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const refWorkerStatus = async () => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/reference/worker/status`);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const refIllness = async (params) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/reference/illness`, params);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const createIllness = async (params) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/create/illness`, params);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const getIllness = async (params) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/get/illness`, params);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const getRef = async (params) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/get/ref`, params);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const getRefUniSP = async (params) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/reference/uni-sp`, params);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const getRefFieldWork = async (params) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/reference/field-work`, params);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const updateJpExtensionCn = async (params) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/update/jp-extension-cn`, params);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const createAchievementEducation = async (params) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/create/achievement/education`, params);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const getAchievementEducation = async () => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/get/achievement/education`);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const updateAchievementEducation = async (params) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/update/achievement/education`, params);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const deleteAchievementEducation = async (params) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/delete/achievement/education`, params);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const getJpExtensionCnForm = async (params) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/get/jp-extension-form/cn`, params);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const updateJpExtensionCnForm = async (params) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/update/jp-extension-form/cn`, params);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const sendForgotPassword = async (params) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/send-forgot-password`, params);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const updateForgotPassword = async (params) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/update-forgot-password`, params);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const updateCnReimburse = async (params) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/update/candidate-reimburse`, params);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const getCandidateNeop = async (params) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/get/candidate-neop`, params);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const downloadFLK = async (params) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/candidate/flk`, params);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const checkInvitedGenerator = async (params) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/check/invited-generator`, params);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const updateInvitedGenerator = async (params) => {
  try {
    const axios = await axiosGlobal();
    const data = await axios.post(`/update/invited-generator`, params);
    if (data) {
      return data.data;
    }
  } catch (err) {
    console.log(err);
    return err.response;
  }
};

export const setSnackbarOpen = (type, msg) => {
  dispatch({
    type: SET_SNACKBAR_OPEN,
    payload: { open: true, type: type, msg: msg },
  });
};

export const setSnackbarClose = () => {
  dispatch({
    type: SET_SNACKBAR_CLOSE,
    payload: { open: false },
  });
};
