import React, { useState } from "react";
import { useDrop } from "react-dnd";
import { ITEM, ELEMENT, ROW, COLUMN } from "./library";
const ACCEPTS = [ITEM, ELEMENT, ROW, COLUMN];

const DropZone = ({ data, onDrop, setDropArea, className, dropArea }) => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ACCEPTS,
    drop: (item, monitor) => {
      onDrop(data, item);
    },
    canDrop: (item, monitor) => {
      const dropZonePath = data.path;
      const splitDropZonePath = dropZonePath.split("-");
      const itemPath = item.path;

      if (!itemPath) {
        if (
          item.variant.indexOf("section") !== -1 &&
          splitDropZonePath.length > 1
        ) {
          return false;
        }
        
        if (splitDropZonePath.length === 2) {
          setDropArea(dropZonePath);
        }

        return true;
      }
      const splitItemPath = itemPath.split("-");
      const parentDropInChild = splitItemPath.length < splitDropZonePath.length;
      const childDropInParent = splitItemPath.length > splitDropZonePath.length;
      if (parentDropInChild) return false;
      if (childDropInParent) return false;

      if (itemPath === dropZonePath) {
        // Current item can't possible move to it's own location
        return false;
      }

      // Current area
      if (splitItemPath.length === splitDropZonePath.length) {
        // WITHIN PARENT
        const pathToItem = splitItemPath.slice(0, -1).join("-");
        const currentItemIndex = Number(splitItemPath.slice(-1)[0]);
        const pathToDropZone = splitDropZonePath.slice(0, -1).join("-");

        if (pathToItem === pathToDropZone && splitItemPath.length === 2) {
          // setpath to passed in column
          setDropArea(dropZonePath);
        }

        if (pathToItem !== pathToDropZone) {
          // can't possible move to diff sub parent (col)
          return false;
        }
      }

      return true;
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  // if (!itmPath && setDropArea) setDropArea(canDrop || colPath);
  // console.log(canDrop, className);
  return (
    <div
      ref={drop}
      style={{
        height: !canDrop
          ? null
          : className === "horizontal" && canDrop
          ? null
          : "2rem",

        width: !canDrop
          ? null
          : className === "horizontal" && canDrop
          ? "1rem"
          : "100%",

        backgroundColor:
          className === "vertical" && canDrop
            ? "#86f55c"
            : dropArea && canDrop
            ? "#59b236"
            : "#326f1a",
      }}
    />
  );
};
export default DropZone;
