import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import {
  Media,
  Spacing,
  Video,
  Input,
  listEditElements,
  Align,
} from "../libraryProcess";
import {
  setBackdrop,
  updateInfoInstitution,
  uploadFoto,
  customPageAPI,
  getInfoInstitution,
  setNavChanged,
} from "src/redux/actions";
import { domain } from "src/components/Layout/dummy";
import {
  ArrowBack,
  ArrowForwardIosSharp,
  Delete,
  FileCopy,
  Home,
  InsertDriveFile,
  Photo,
  Videocam,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { ColorPicker } from "material-ui-color";
import localforage from "localforage";
import shortid from "shortid";
import { compareArrayOfObjects } from "src/functions/compareArrayOfObjects";
import { connect } from "react-redux";
import "../../../../App.css";
import { version } from "src/configs/globalVariable";

const buttonComDrawer = {
  borderRadius: "0.75rem",
  py: 0.5,
  "&:hover": { backgroundColor: "#ffff" },
};

const SlideDrawer = ({
  stateDrawer,
  setStateDrawer,
  data,
  setData,
  rowObjEdit,
  setRowObjEdit,
  openConfirm,
  setOpenConfirm,
  loadingDeleteNav,
  setLoadingDeleteNav,
  handleVisibility,
  handleChangePage,
  pagePreview,
}) => {
  const renderNavDrawer = () => {
    return (
      <NavDrawer
        handleChangePage={handleChangePage}
        data={data}
        pagePreview={pagePreview}
      />
    );
  };

  const renderComDrawer = () => {
    return (
      <ComDrawer
        data={data}
        setData={setData}
        rowObjEdit={rowObjEdit}
        setRowObjEdit={setRowObjEdit}
        openConfirm={openConfirm}
        setOpenConfirm={setOpenConfirm}
        loadingDeleteNav={loadingDeleteNav}
        setLoadingDeleteNav={setLoadingDeleteNav}
        handleVisibility={handleVisibility}
        pagePreview={pagePreview}
      />
    );
  };

  return (
    <Stack
      sx={{
        height: "100%",
        width:
          stateDrawer && stateDrawer === "nav"
            ? "calc((3/12) * 100vw)"
            : stateDrawer
            ? "calc((4/12) * 100vw)"
            : 0,
        position: "fixed",
        top: 0,
        zIndex: 999,
        left: "calc((0.5/12) * 100vw)", // drawer lg={0.5}
        backgroundColor: "#fff",
        overflowX: "auto",
        transition: "0.5s",
        boxShadow:
          "rgba(0, 0, 0, 0.09) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 8px 8px, rgba(0, 0, 0, 0.09) 0px 16px 8px",
      }}
      className="custom-scrollbar y"
    >
      <IconButton
        sx={{
          position: "absolute",
          top: 0,
          right: 0,
          mr: 2,
          my: 2,
          p: 1,
          pr: 1.5,
          width: "40px",
          height: "40px",
          "&:hover": {
            boxShadow: `0 0 1px 0 rgba(0,0,0,0.31), 0 4px 8px -2px rgba(0,0,0,0.25)`,
            backgroundColor: "#f0f8ff",
            cursor: "pointer",
          },
        }}
        onClick={() => {
          setStateDrawer("");
          setStateDrawer("");
        }}
      >
        {/* <i class="fi fi-br-arrow-small-left"></i> */}
        <ArrowBack fontSize="small" sx={{ width: "1rem", height: "1rem" }} />
      </IconButton>
      <Grid
        item
        container
        className="custom-scrollbar y"
        sx={{
          position: "absolute",
          top: "40px",
          left: 0,
          p: 2,
          overflowY: "auto",
        }}
      >
        {stateDrawer === "nav" && renderNavDrawer()}
        {stateDrawer === "com" && renderComDrawer()}
      </Grid>
    </Stack>
  );
};
const mapStateToProps = (state) => ({
  pagePreview: state.preview.pagePreview,
});

export default connect(mapStateToProps)(SlideDrawer);

const NavDrawer = ({ handleChangePage, data, pagePreview }) => {
  const [dataNav, setDataNav] = useState([]);
  const [clickedNav, setClickedNav] = useState(false);

  useEffect(() => {
    setDataNavAll();
  }, [data]);

  const setDataNavAll = () => {
    const headerSect = data[0];
    const header = headerSect.children.slice(-1)[0];
    const arr = [
      ...header.children[0].children[0].mainLink,
      ...header.children[0].children[0].link,
    ];
    setDataNav(arr);
  };

  const navStyle = {
    width: "100%",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#f0f8ff",
      borderRadius: "0.75rem",
    },
  };

  const iconStyle = {
    mr: 1,
    "&:hover": {
      backgroundColor: "transparent",
    },
  };

  return (
    <>
      <Typography
        variant="subtitle1"
        color="text.primary"
        sx={{ fontWeight: "bold", ml: 0.5, mb: 1.75 }}
      >
        Navigasi Website
      </Typography>
      {/* "Article" */}
      {dataNav
        .filter((x) => x.url !== `/${version}/user/vacancy` && !x.isExternal)
        .map((nav, i) => (
          <>
            <Stack
              key={i}
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
              sx={{
                ...navStyle,
                backgroundColor:
                  nav.url === pagePreview ||
                  (nav.idGroup && nav.idGroup === pagePreview.idGroup)
                    ? "#f0f8ff"
                    : "transparent",
              }}
              onClick={() => {
                if (nav.isGroup && nav.children?.length > 0) {
                  setClickedNav(clickedNav === `nav-${i}` ? false : `nav-${i}`);
                } else {
                  handleChangePage(nav);
                }
              }}
            >
              <IconButton sx={{ ...iconStyle }}>
                {/* {nav.url === `/${version}/user` ? (
                  <i class="fi fi-sr-home"></i>
                ) : nav.isGroup && nav.children?.length > 0 ? (
                  <i class="fi fi-sr-copy"></i>
                ) : (
                  <i class="fi fi-sr-file"></i>
                )} */}
                {nav.url === `/${version}/user` ? (
                  <Home />
                ) : nav.isGroup && nav.children?.length > 0 ? (
                  <FileCopy
                    fontSize="small"
                    sx={{ width: "1.35rem", height: "1.35rem" , mr: 0.35}}
                  />
                ) : (
                  <InsertDriveFile
                    fontSize="small"
                    sx={{ width: "1.35rem", height: "1.35rem", mr: 0.35 }}
                  />
                )}
              </IconButton>
              <Typography variant="body2" color="text.primary">
                {nav.text}
              </Typography>
            </Stack>

            {clickedNav === `nav-${i}` &&
              nav.children &&
              nav.children.length > 0 && (
                <>
                  {nav.children.map((nav2, z) => (
                    <Stack
                      key={z}
                      direction="row"
                      alignItems="center"
                      justifyContent="flex-start"
                      sx={{
                        ml: 4,
                        ...navStyle,
                        backgroundColor:
                          nav2.id === pagePreview.id
                            ? "#f0f8ff"
                            : "transparent",
                      }}
                      onClick={() =>
                        handleChangePage({ idGroup: nav.idGroup, ...nav2 })
                      }
                    >
                      <IconButton sx={{ ...iconStyle }}>
                        <InsertDriveFile
                          fontSize="small"
                          sx={{ width: "1.35rem", height: "1.35rem" }}
                        />
                      </IconButton>
                      <Typography variant="body2" color="text.primary">
                        {nav2.text}
                      </Typography>
                    </Stack>
                  ))}
                </>
              )}
          </>
        ))}
    </>
  );
};

const ComDrawer = ({
  data,
  setData,
  rowObjEdit,
  openConfirm,
  setOpenConfirm,
  loadingDeleteNav,
  setLoadingDeleteNav,
  handleVisibility,
  pagePreview,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [form, setForm] = useState({});
  const [clicked, setClicked] = useState("el");
  const [el, setEl] = useState({});
  const [col, setCol] = useState({});
  const [row, setRow] = useState({});
  const [idxRow, setIdxRow] = useState(0);
  const [idxCol, setIdxCol] = useState(0);
  const [idxEl, setIdxEl] = useState(0);
  const [loading, setLoading] = useState(false);
  const [validate, setValidate] = useState(false);
  const [bgState, setBgState] = useState(false);
  const [propsVal, setPropsVal] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    initData();
  }, [clicked, bgState]);

  useEffect(() => {
    if (!loadingDeleteNav) {
      handleNavigation("delete", openConfirm.shortDetailKey, openConfirm.i);
      setOpenConfirm(false);
      setLoadingDeleteNav(true);
    }
  }, [loadingDeleteNav]);

  const initData = (copyClicked) => {
    let idxRow = data.findIndex((v) => v.id === rowObjEdit.rowId);
    const rowData = data[idxRow];
    // col
    const idxCol = rowData.children.findIndex((v) => v.id === rowObjEdit.colId);
    const colData = rowData.children[idxCol];
    //el
    const idxEl = colData.children.findIndex((el) => el.id === rowObjEdit.elId);
    const elData = colData.children[idxEl];

    setEl(elData);
    setCol(colData);
    setRow(rowData);
    setIdxRow(idxRow);
    setIdxCol(idxCol);
    setIdxEl(idxEl);

    let x = copyClicked || clicked;

    if (x === "el") setForm(elData);
    else if (x === "con col") setForm(colData);
    else if (x === "con row") {
      if (bgState === "bg-video") {
        let copyForm = { ...rowData };
        let copyProps =
          clicked === "con row" && propsVal === "props 0"
            ? copyForm.sectProps
            : copyForm.props;

        if (copyProps.backgroundColor || copyProps.backgroundImage) {
          delete copyProps.backgroundColor;
          delete copyProps.backgroundImage;
          setForm({
            ...rowData,
            src: "",
            poster: "",
            sectCn: "root-stack",
            rowCn: "root-stack",
          });
        }
      } else setForm(rowData);
    }
  };

  const handleApply = async () => {
    setBackdrop(true);
    const token = await localforage.getItem("token");

    let copyData = [...data];
    const copyRow = copyData[idxRow];
    const copyCol = copyRow.children[idxCol];

    if (clicked === "con row") copyData[idxRow] = form;
    if (clicked === "con col") copyRow.children[idxCol] = form;
    if (clicked === "el") copyCol.children[idxEl] = form;

    // console.log(copyData);
    setData(copyData);

    // set to backend
    const obj = { token, domain };
    let type, resData, landingContentType, id;
    if (pagePreview === `/${version}/user`) {
      resData = await localforage.getItem("data");
      type = "content";
    } else if (pagePreview === `/${version}/user/FAQ`) {
      resData = await localforage.getItem("faq");
      type = "faq";
    } else {
      if (pagePreview.id) {
        type = "content";
        landingContentType = "landing_content_id";
        id = pagePreview.id;
      } else {
        type = "group";
        landingContentType = "landing_content_group_id";
        id = pagePreview.idGroup;
      }
      const res = await customPageAPI("get", type, {
        domain: domain,
        [`${landingContentType}`]: id,
      });
      resData = res.data[0].content;
    }

    const compareData = compareArrayOfObjects(copyData, resData);
    if (!compareData) {
      if (typeof pagePreview === "string") {
        const jsonData = JSON.stringify(copyData);
        await updateInfoInstitution(type, {
          ...obj,
          [`${type}`]: jsonData,
        });
        getInfoInstitution();
      } else {
        await customPageAPI("update", type, {
          [`${landingContentType}`]: id,
          title: pagePreview.text,
          content: copyData,
        });
      }
    }

    if (pagePreview === `/${version}/user`) {
      let headerAPI = { ...resData[0] };
      let headerTemp = { ...copyData[0] };
      const headerChildrenAPI = headerAPI.children.slice(-1);
      const headerChildrenTemp = headerTemp.children.slice(-1);
      const compareHeader = compareArrayOfObjects(
        headerChildrenTemp,
        headerChildrenAPI
      );

      let compareResult = [];
      if (!compareHeader) {
        const headerNavAPI = headerChildrenAPI[0]?.children[0]?.children[0];
        const headerNavTemp = headerChildrenTemp[0]?.children[0]?.children[0];
        const arr = [
          ...headerNavAPI.mainLink.filter((v) => v.idGroup),
          ...headerNavAPI.link.filter((v) => v.idGroup),
        ];
        const arrTemp = [
          ...headerNavTemp.mainLink.filter((v) => v.idGroup),
          ...headerNavTemp.link.filter((v) => v.idGroup),
        ];

        arrTemp.forEach((navTemp) => {
          let i = false;
          arr.forEach((v) => {
            if (v.idGroup === navTemp.idGroup) {
              i = true;
              if (!compareArrayOfObjects([navTemp], [v]))
                return compareResult.push(navTemp);
            }
          });
          if (!i) compareResult.push(navTemp);
        });
        if (compareResult.length > 0) handleNewPage(compareResult, copyData);
      }
    }

    setBackdrop(false);
  };

  const handleNewPage = (compareResult, tempData) => {
    const insertData = async (page) => {
      let type, landingContentType, id;
      if (page.id) {
        type = "content";
        landingContentType = "landing_content_id";
        id = page.id;
      } else {
        type = "group";
        landingContentType = "landing_content_group_id";
        id = page.idGroup;
      }

      const resData = await customPageAPI("get", type, {
        domain: domain,
        [`${landingContentType}`]: id,
      });

      if (resData.data.length === 0) {
        const obj = {
          domain: domain,
          [`${landingContentType}`]: id,
          title: page.text,
          content: tempData,
        };
        if (type === "content") obj.landing_content_group_id = page.idGroup;
        await customPageAPI("insert", type, {
          ...obj,
        });
      }
    };

    compareResult.forEach((page) => {
      if (page.children && page.children.length > 0) {
        page.children.forEach((pageChild) =>
          insertData({ ...pageChild, idGroup: page.idGroup })
        );
      } else {
        insertData(page);
      }
    });
    setNavChanged(true);
    getInfoInstitution();
  };

  const setCopyFormByDetailKey = (copyForm, key, val, detailKey) => {
    // console.log(copyForm, key, val, detailKey, "SETCOPYFORMBYDETAILKEY");
    let copyFormDetail;
    let newObj;

    let detailKey0, detailKey1, detailKey2, detailKey3, detailKey4, detailKey5;

    if (detailKey) {
      [detailKey0, detailKey1, detailKey2, detailKey3, detailKey4, detailKey5] =
        detailKey;

      switch (detailKey.length) {
        case 1:
          copyFormDetail = copyForm[detailKey0];
          if (!key) return copyFormDetail;

          newObj = { ...copyFormDetail }; // for deep object
          newObj[key] = val;
          copyForm[detailKey0] = newObj;
          break;
        case 2:
          copyFormDetail = copyForm[detailKey0][detailKey1];
          if (!key) return copyFormDetail;

          newObj = { ...copyFormDetail };
          newObj[key] = val;
          copyForm[detailKey0][detailKey1] = newObj;
          break;
        case 3:
          copyFormDetail = copyForm[detailKey0][detailKey1][detailKey2];
          if (!key) return copyFormDetail;

          newObj = { ...copyFormDetail };
          newObj[key] = val;
          copyForm[detailKey0][detailKey1][detailKey2] = newObj;
          break;
        case 4:
          copyFormDetail =
            copyForm[detailKey0][detailKey1][detailKey2][detailKey3];
          if (!key) return copyFormDetail;

          newObj = { ...copyFormDetail };
          newObj[key] = val;
          copyForm[detailKey0][detailKey1][detailKey2][detailKey3] = newObj;
          break;
        case 5:
          copyFormDetail =
            copyForm[detailKey0][detailKey1][detailKey2][detailKey3][
              detailKey4
            ];
          if (!key) return copyFormDetail;

          newObj = { ...copyFormDetail };
          newObj[key] = val;
          copyForm[detailKey0][detailKey1][detailKey2][detailKey3][detailKey4] =
            newObj;
          break;
        case 6:
          copyFormDetail =
            copyForm[detailKey0][detailKey1][detailKey2][detailKey3][
              detailKey4
            ][detailKey5];
          if (!key) return copyFormDetail;

          newObj = { ...copyFormDetail };
          newObj[key] = val;
          copyForm[detailKey0][detailKey1][detailKey2][detailKey3][detailKey4][
            detailKey5
          ] = newObj;
          break;
        default:
          break;
      }
    } else {
      if (key) copyForm[key] = val;
      else return copyForm;
    }

    if (bgState === "bg-img" && key) {
      delete copyFormDetail.backgroundColor;
      if (propsVal === "props 0") {
        delete copyForm.src;
        delete copyForm.poster;
      }
    } else if (bgState === "bg-color") {
      delete copyFormDetail.backgroundImage;
      if (propsVal === "props 0") {
        delete copyForm.src;
        delete copyForm.poster;
      }
    } else if (key === "isGroup") {
      // grouping navigation
      if (val) {
        delete copyForm[detailKey0][detailKey1][detailKey2][detailKey3]["url"];
      } else {
        delete copyForm[detailKey0][detailKey1][detailKey2][detailKey3][
          "idGroup"
        ];
        delete copyForm[detailKey0][detailKey1][detailKey2][detailKey3][
          "children"
        ];
      }
    }
    return copyForm;
  };

  const handleChange = (key, value, type, detailKey) => {
    // console.log(key, value, type, detailKey, "handleChnage");
    if (key === "src") setValidate(false);
    let copyForm = { ...form };

    let val;
    let matchNull;
    if (type) matchNull = value.match("null");
    if (type === "link" && matchNull && matchNull.length !== 0) {
    } else if (type === "props-style-spacing" && value) {
      val = `${value}rem`;
    } else if (type === "props-style") {
      val = key === "maxWidth" ? `${value}%` : value;
    } else val = value;

    // inject video in element
    if (form.variant === "element2" && key === "src") {
      const els = document.querySelectorAll("video");
      els.forEach((el) => {
        if (el.id !== "video-bg") el.src = val;
      });
    }
    const bgVideoExist = document.querySelector("#video-bg");
    if (bgState === "bg-video" && key === "src" && bgVideoExist) {
      bgVideoExist.src = val;
    }

    if (bgState === "bg-img" && key === "src") {
      copyForm = setCopyFormByDetailKey(
        copyForm,
        "backgroundImage",
        val,
        detailKey
      );
    } else if (bgState === "bg-color") {
      copyForm = setCopyFormByDetailKey(
        copyForm,
        "backgroundColor",
        val?.css?.backgroundColor,
        detailKey
      );
    } else {
      copyForm = setCopyFormByDetailKey(copyForm, key, val, detailKey);
    }

    // classnames
    if (form.elCn === "img-stack") {
      const copyData = [...data];
      const copyRow = copyData[idxRow];
      copyRow.children.forEach((col) => {
        // if (!col.colCn) col.props["mb"] = "2rem";
        col.props[key] = val;
      });
      setForm(copyForm);
      setData(copyData);
      return;
    }

    // console.log(copyForm);
    setForm(copyForm);
  };

  const handleNavigation = (action, detailKey, childKeys) => {
    // console.log(action, detailKey, childKeys);
    let copyForm = { ...form };
    let getFormDetail = setCopyFormByDetailKey(
      copyForm,
      undefined,
      undefined,
      detailKey
    );
    const detailKeyByAction =
      action.indexOf("simple-scroll-card") !== -1
        ? undefined
        : detailKey.slice(0, -1);

    let arr = [...getFormDetail]; // array

    if (action.indexOf("add") !== -1) {
      let item = { ...arr[0] };
      childKeys.forEach((childKey) => {
        if (childKey === "id") item[childKey] = shortid.generate();
        else if (childKey === "visibility") item[childKey] = true;
        else item[childKey] = "";
      });
      arr.push(item);
      copyForm = setCopyFormByDetailKey(
        copyForm,
        detailKey[detailKey.length - 1],
        arr,
        detailKeyByAction && detailKeyByAction.length > 0
          ? detailKeyByAction
          : undefined
      );
    } else {
      arr.splice(childKeys, 1);
      copyForm = setCopyFormByDetailKey(
        copyForm,
        detailKey[detailKey.length - 1],
        arr,
        detailKeyByAction && detailKeyByAction.length > 0
          ? detailKeyByAction
          : undefined
      );
    }
    setForm(copyForm);
    handleApply();
  };

  const handleChangeFile = async (key, e, detailKey) => {
    let copyForm = { ...form };
    let getFormDetail = setCopyFormByDetailKey(
      copyForm,
      undefined,
      undefined,
      detailKey
    );

    let varForm =
      bgState === "bg-video"
        ? { ...form, variant: "element2" }
        : bgState === "bg-img"
        ? { ...form, variant: "element3" }
        : form;
    if (detailKey) varForm = getFormDetail;

    const con1 =
      varForm.variant === "element2" && key === "src" ? 10000000 : 1000000;
    const con1Text =
      varForm.variant === "element2" && key === "src" ? "10MB" : "1MB";
    const con2 =
      varForm.variant === "element2" && key === "src"
        ? "video/mp4"
        : "image/png, image/jpg, image/jpeg";

    setLoading(true);

    const file = e.target.files[0];
    if (file.size >= con1) {
      setValidate(`Exceeded maximum size, more than ${con1Text}`);
      setLoading(false);
      return;
    }

    if (`${con2}`.indexOf(file.type) === -1) {
      setValidate(`File type is not allowed other than ${con2}`);
      setLoading(false);
      return;
    }

    setValidate(false);
    const token = await localforage.getItem("token");
    let formData = new FormData();
    formData.append("token", token);
    formData.append("image", e.target.files[0]);
    formData.append("domain", domain);

    const res = await uploadFoto(formData);
    if (res.code === 200) {
      if (bgState === "bg-img" && key === "src") {
        copyForm = setCopyFormByDetailKey(
          copyForm,
          "backgroundImage",
          res.data.path,
          detailKey
        );
      } else {
        copyForm = setCopyFormByDetailKey(
          copyForm,
          key,
          res.data.path,
          detailKey
        );
      }
      setForm(copyForm);
    }
    setLoading(false);
  };

  return (
    <>
      <Typography
        variant="subtitle1"
        color="text.primary"
        sx={{ fontWeight: "bold", ml: 0.5 }}
      >
        Edit
      </Typography>
      <Stack
        direction="row"
        sx={{
          backgroundColor: "#f0f8ff",
          width: "100%",
          borderRadius: "0.75rem",
          mt: 2,
          p: 0.75,
          boxShadow: `0 0 1px 0 rgba(0,0,0,0.31), 0 4px 8px -2px rgba(0,0,0,0.25)`,
        }}
      >
        {["con row", "con col", "el"].map((v) => (
          <Button
            sx={{
              backgroundColor: clicked === v ? "#ffff" : "transparent",
              border: clicked === v && "1px solid lightgrey",
              mr: 0.75,
              ...buttonComDrawer,
            }}
            onClick={() => {
              setPropsVal(false);
              setClicked(v);
              initData(v);
            }}
          >
            <Typography variant="body2" color="text.primary">
              {v === "con row" ? "Row" : v === "con col" ? "Column" : "Element"}
            </Typography>
          </Button>
        ))}
      </Stack>
      {clicked === "con row" && idxRow > 0 && idxRow < data.length - 1 && (
        <Stack
          sx={{
            width: "100%",
            px: 2,
            py: 0.5,
            mt: 2,
          }}
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Typography variant="body2" color="text.primary" sx={{ mr: 4 }}>
            Tindakan lainnya
          </Typography>
          <IconButton onClick={() => handleVisibility(idxRow)}>
            {row.visibility && <Visibility sx={{ color: "#0000ff" }} />}
            {!row.visibility && <VisibilityOff />}
          </IconButton>
          <IconButton
            onClick={() => setOpenConfirm({ type: "section", idx: idxRow })}
          >
            <Delete color="error" />
          </IconButton>
        </Stack>
      )}

      <Stack
        direction="column"
        sx={{
          width: "100%",
          mt: 2,
          p: 0.75,
        }}
      >
        {clicked === "el" &&
          listEditElements(
            el,
            form,
            handleChange,
            handleChangeFile,
            handleNavigation,
            loading,
            validate,
            setOpenConfirm
          )}
        {clicked === "con row" && (
          <EditContainerRow
            form={form}
            handleChange={handleChange}
            handleChangeFile={handleChangeFile}
            loading={loading}
            validate={validate}
            bgState={bgState}
            setBgState={setBgState}
            propsVal={propsVal}
            setPropsVal={setPropsVal}
            pagePreview={pagePreview}
          />
        )}
        {clicked === "con col" && (
          <EditContainerCol
            form={form}
            handleChange={handleChange}
            handleChangeFile={handleChangeFile}
            loading={loading}
            validate={validate}
            bgState={bgState}
            setBgState={setBgState}
            propsVal={propsVal}
            setPropsVal={setPropsVal}
          />
        )}
      </Stack>
      <Stack direction="row" justifyContent="flex-end" sx={{ width: "100%" }}>
        <Button
          sx={{
            width: "fit-content",
            backgroundColor: "#f0f8ff",
            color: `${theme.palette.text.primary}`,
            mt: 2,
            mr: 1,
          }}
          onClick={() => handleApply(clicked)}
        >
          Terapkan
        </Button>
      </Stack>
    </>
  );
};

const BgGroup = ({
  arr,
  bgState,
  setBgState,
  form,
  handleChange,
  handleChangeFile,
  loading,
  validate,
  detailKey,
}) => {
  return (
    <>
      <Stack direction="row" justifyContent="center">
        {arr.map((x, i) => (
          <IconButton
            key={i}
            sx={{ mx: x === "bg-color" ? 0 : 0.5, my: 0 }}
            onClick={() => setBgState(x)}
          >
            {x === "bg-video" && <Videocam />}
            {x === "bg-img" && <Photo />}
            {x === "bg-color" && (
              <ColorPicker
                hideTextfield
                value={form[detailKey]?.backgroundColor}
                onChange={(e) =>
                  handleChange("backgroundColor", e, undefined, detailKey)
                }
              />
            )}
          </IconButton>
        ))}
      </Stack>
      <Stack direction="row">
        {bgState === "bg-video" && (
          <Video
            form={{ ...form, variant: "element2" }}
            aspectRatio={form?.sectProps?.aspectRatio}
            handleChange={handleChange}
            handleChangeFile={handleChangeFile}
            loading={loading}
            validate={validate}
            detailKey={detailKey}
            bg="true"
          />
        )}
        {bgState === "bg-img" && (
          <Media
            form={{
              ...form,
              variant: "element3",
              src: form[detailKey]?.backgroundImage,
            }}
            aspectRatio={form[detailKey]?.aspectRatio}
            handleChange={handleChange}
            handleChangeFile={handleChangeFile}
            loading={loading}
            validate={validate}
            bg="true"
            detailKey={detailKey}
          />
        )}
      </Stack>
    </>
  );
};

const EditContainerRow = ({
  form,
  handleChange,
  handleChangeFile,
  loading,
  validate,
  bgState,
  setBgState,
  propsVal,
  setPropsVal,
  // pagePreview,
}) => {
  let arr;
  // (typeof pagePreview === "string" && pagePreview === `/${version}/user`) ||
  //   typeof pagePreview === "object";
  if (propsVal === "props 0") {
    arr = ["bg-video", "bg-img", "bg-color"];
  } else if (propsVal === "props 1") {
    arr = ["bg-img", "bg-color"];
    // faq dan pagePreview yang lain masih diberi pilihan video
    // || typeof pagePreview === "string" && pagePreview === `/${version}/user/FAQ`;
  }
  const theme = useTheme();

  return (
    <>
      <Stack sx={{ py: 1.75 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            color: `${theme.palette.text.primary}`,
            "&:hover": { cursor: "pointer" },
          }}
          onClick={() =>
            setPropsVal(propsVal === "props 0" ? false : "props 0")
          }
        >
          <Typography variant="body2" color="inherit">
            Background
          </Typography>
          <IconButton>
            <ArrowForwardIosSharp
              sx={{
                width: "0.75rem",
                transition: "all linear .2s",
                transform:
                  propsVal === "props 0" ? "rotate(90deg)" : "rotate(0deg)",
              }}
            />
          </IconButton>
        </Stack>
        {propsVal === "props 0" && (
          <BgGroup
            arr={arr}
            bgState={bgState}
            setBgState={setBgState}
            form={form}
            handleChange={handleChange}
            handleChangeFile={handleChangeFile}
            loading={loading}
            validate={validate}
            detailKey={["sectProps"]}
          />
        )}
      </Stack>
      <Divider sx={{ backgroundColor: "#f8f8f8" }} />
      <Stack sx={{ py: 1.75 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            color: `${theme.palette.text.primary}`,
            "&:hover": { cursor: "pointer" },
          }}
          onClick={() =>
            setPropsVal(propsVal === "props 1" ? false : "props 1")
          }
        >
          <Typography variant="body2" color="inherit">
            Inner Background
          </Typography>
          <IconButton>
            <ArrowForwardIosSharp
              sx={{
                width: "0.75rem",
                transition: "all linear .2s",
                transform:
                  propsVal === "props 1" ? "rotate(90deg)" : "rotate(0deg)",
              }}
            />
          </IconButton>
        </Stack>
        {propsVal === "props 1" && (
          <BgGroup
            arr={arr}
            bgState={bgState}
            setBgState={setBgState}
            form={form}
            handleChange={handleChange}
            handleChangeFile={handleChangeFile}
            loading={loading}
            validate={validate}
            detailKey={["props"]}
          />
        )}
      </Stack>

      <Divider sx={{ backgroundColor: "#f8f8f8" }} />
      <Stack sx={{ py: 1.75 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            color: `${theme.palette.text.primary}`,
            "&:hover": { cursor: "pointer" },
          }}
          onClick={() =>
            setPropsVal(propsVal === "props 2" ? false : "props 2")
          }
        >
          <Typography variant="body2" color="inherit">
            Padding
          </Typography>
          <IconButton>
            <ArrowForwardIosSharp
              sx={{
                width: "0.75rem",
                transition: "all linear .2s",
                transform:
                  propsVal === "props 2" ? "rotate(90deg)" : "rotate(0deg)",
              }}
            />
          </IconButton>
        </Stack>
        {propsVal === "props 2" && (
          <Spacing
            state="padding"
            form={form?.sectProps}
            handleChange={handleChange}
            detailKey={["sectProps"]}
          />
        )}
      </Stack>
      {form.sectCn !== "root-stack" && (
        <>
          <Divider sx={{ backgroundColor: "#f8f8f8" }} />
          <Stack sx={{ py: 0 }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ color: `${theme.palette.text.primary}` }}
            >
              <Typography variant="body2" color="inherit">
                Max Width
              </Typography>
              <Input
                props={{
                  width: "7rem",
                  placeholder: "eg: 95",
                  name: "maxWidth",
                  value: form.props["maxWidth"]
                    ? form.props["maxWidth"]?.split("%")[0]
                    : "",
                  onChange: (e) =>
                    handleChange(e.target.name, e.target.value, "props-style", [
                      "props",
                    ]),
                }}
              />
            </Stack>
          </Stack>
        </>
      )}
      <Divider sx={{ backgroundColor: "#f8f8f8" }} />
      <Stack sx={{ py: 1.75 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            color: `${theme.palette.text.primary}`,
            "&:hover": { cursor: "pointer" },
          }}
          onClick={() =>
            setPropsVal(propsVal === "props 3" ? false : "props 3")
          }
        >
          <Typography variant="body2" color="inherit">
            Align
          </Typography>
          <IconButton>
            <ArrowForwardIosSharp
              sx={{
                width: "0.75rem",
                transition: "all linear .2s",
                transform:
                  propsVal === "props 3" ? "rotate(90deg)" : "rotate(0deg)",
              }}
            />
          </IconButton>
        </Stack>
        {propsVal === "props 3" && (
          <Align
            form={form?.props}
            handleChange={handleChange}
            detailKey={["props"]}
          />
        )}
      </Stack>
    </>
  );
};

const EditContainerCol = ({
  form,
  handleChange,
  handleChangeFile,
  loading,
  validate,
  bgState,
  setBgState,
  propsVal,
  setPropsVal,
}) => {
  let arr;
  arr = ["bg-img", "bg-color"];
  const theme = useTheme();
  return (
    <>
      <Stack sx={{ py: 1.75 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            color: `${theme.palette.text.primary}`,
            "&:hover": { cursor: "pointer" },
          }}
          onClick={() =>
            setPropsVal(propsVal === "props 0" ? false : "props 0")
          }
        >
          <Typography variant="body2" color="inherit">
            Background
          </Typography>
          <IconButton>
            <ArrowForwardIosSharp
              sx={{
                width: "0.75rem",
                transition: "all linear .2s",
                transform:
                  propsVal === "props 0" ? "rotate(90deg)" : "rotate(0deg)",
              }}
            />
          </IconButton>
        </Stack>
        {propsVal === "props 0" && (
          <BgGroup
            arr={arr}
            bgState={bgState}
            setBgState={setBgState}
            form={form}
            handleChange={handleChange}
            handleChangeFile={handleChangeFile}
            loading={loading}
            validate={validate}
            detailKey={["props"]}
          />
        )}
      </Stack>
      <Divider sx={{ backgroundColor: "#f8f8f8" }} />
      <Stack sx={{ py: 1.75 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            color: `${theme.palette.text.primary}`,
            "&:hover": { cursor: "pointer" },
          }}
          onClick={() =>
            setPropsVal(propsVal === "props 1" ? false : "props 1")
          }
        >
          <Typography variant="body2" color="inherit">
            Margin
          </Typography>
          <IconButton>
            <ArrowForwardIosSharp
              sx={{
                width: "0.75rem",
                transition: "all linear .2s",
                transform:
                  propsVal === "props 1" ? "rotate(90deg)" : "rotate(0deg)",
              }}
            />
          </IconButton>
        </Stack>
        {propsVal === "props 1" && (
          <Spacing
            state="margin"
            form={form?.props}
            handleChange={handleChange}
            detailKey={["props"]}
          />
        )}
      </Stack>
      <Divider sx={{ backgroundColor: "#f8f8f8" }} />
      <Stack sx={{ py: 1.75 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            color: `${theme.palette.text.primary}`,
            "&:hover": { cursor: "pointer" },
          }}
          onClick={() =>
            setPropsVal(propsVal === "props 2" ? false : "props 2")
          }
        >
          <Typography variant="body2" color="inherit">
            Padding
          </Typography>
          <IconButton>
            <ArrowForwardIosSharp
              sx={{
                width: "0.75rem",
                transition: "all linear .2s",
                transform:
                  propsVal === "props 2" ? "rotate(90deg)" : "rotate(0deg)",
              }}
            />
          </IconButton>
        </Stack>
        {propsVal === "props 2" && (
          <Spacing
            state="padding"
            form={form?.props}
            handleChange={handleChange}
            detailKey={["props"]}
          />
        )}
      </Stack>
      <Divider sx={{ backgroundColor: "#f8f8f8" }} />
      <Stack sx={{ py: 0 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ color: `${theme.palette.text.primary}` }}
        >
          <Typography variant="body2" color="inherit">
            Column Size
          </Typography>
          <Input
            props={{
              width: "7rem",
              placeholder: "eg: 6",
              name: "colWidth",
              value: form.colWidth,
              onChange: (e) => handleChange(e.target.name, e.target.value),
            }}
          />
        </Stack>
      </Stack>

      {form.colCn === "simpleScroll" && (
        <>
          <Divider sx={{ backgroundColor: "#f8f8f8" }} />
          <Stack sx={{ py: 0 }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ color: `${theme.palette.text.primary}` }}
            >
              <Typography variant="body2" color="inherit">
                Height
              </Typography>
              <Input
                props={{
                  width: "7rem",
                  placeholder: "eg: 25",
                  name: "height",
                  value: form.props["height"]?.split("rem")[0],
                  onChange: (e) =>
                    handleChange(
                      e.target.name,
                      e.target.value,
                      "props-style-spacing",
                      ["props"]
                    ),
                }}
              />
            </Stack>
          </Stack>
        </>
      )}
      {/* from template usually comes with no align */}
      {(form.colCn !== "header" || form.colCn !== "footer") && (
        <>
          <Divider sx={{ backgroundColor: "#f8f8f8" }} />
          <Stack sx={{ py: 1.75 }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{
                color: `${theme.palette.text.primary}`,
                "&:hover": { cursor: "pointer" },
              }}
              onClick={() =>
                setPropsVal(propsVal === "props 3" ? false : "props 3")
              }
            >
              <Typography variant="body2" color="inherit">
                Align
              </Typography>
              <IconButton>
                <ArrowForwardIosSharp
                  sx={{
                    width: "0.75rem",
                    transition: "all linear .2s",
                    transform:
                      propsVal === "props 3" ? "rotate(90deg)" : "rotate(0deg)",
                  }}
                />
              </IconButton>
            </Stack>

            {propsVal === "props 3" && (
              <Align
                form={form?.props}
                handleChange={handleChange}
                detailKey={["props"]}
              />
            )}
          </Stack>
        </>
      )}
    </>
  );
};
